export default /*
File generated by js-routes 1.4.14
Based on Rails 7.0.8 routes of Robis::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, error, result,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (this.jQuery && (this.jQuery.type != null)) {
        return this.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// active_liquid_containers_stocks => /stocks/active_liquid_containers(.:format)
  // function(options)
  active_liquid_containers_stocks_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"stocks",false],[2,[7,"/",false],[2,[6,"active_liquid_containers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// active_plate_containers_stocks => /stocks/active_plate_containers(.:format)
  // function(options)
  active_plate_containers_stocks_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"stocks",false],[2,[7,"/",false],[2,[6,"active_plate_containers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// active_product_containers_api_service_v1_stocks => /api/service/v1/stocks/active_product_containers(.:format)
  // function(options)
  active_product_containers_api_service_v1_stocks_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"service",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"stocks",false],[2,[7,"/",false],[2,[6,"active_product_containers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// active_sauce_containers_api_service_v1_stocks => /api/service/v1/stocks/active_sauce_containers(.:format)
  // function(options)
  active_sauce_containers_api_service_v1_stocks_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"service",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"stocks",false],[2,[7,"/",false],[2,[6,"active_sauce_containers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// active_solid_containers_stocks => /stocks/active_solid_containers(.:format)
  // function(options)
  active_solid_containers_stocks_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"stocks",false],[2,[7,"/",false],[2,[6,"active_solid_containers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_alergen => /admin/alergens/:id(.:format)
  // function(id, options)
  admin_alergen_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"alergens",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_alergens => /admin/alergens(.:format)
  // function(options)
  admin_alergens_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"alergens",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_boiler_batch_snapshot => /admin/boiler_batch_snapshots/:id(.:format)
  // function(id, options)
  admin_boiler_batch_snapshot_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"boiler_batch_snapshots",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_boiler_batch_snapshots => /admin/boiler_batch_snapshots(.:format)
  // function(options)
  admin_boiler_batch_snapshots_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"boiler_batch_snapshots",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_configurator_order => /admin/configurator_orders/:id(.:format)
  // function(id, options)
  admin_configurator_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"configurator_orders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_configurator_orders => /admin/configurator_orders(.:format)
  // function(options)
  admin_configurator_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"configurator_orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_cooking_mode => /admin/cooking_modes/:id(.:format)
  // function(id, options)
  admin_cooking_mode_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"cooking_modes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_cooking_modes => /admin/cooking_modes(.:format)
  // function(options)
  admin_cooking_modes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"cooking_modes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_currencies => /admin/currencies(.:format)
  // function(options)
  admin_currencies_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currencies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_currency => /admin/currencies/:id(.:format)
  // function(id, options)
  admin_currency_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currencies",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_custom_vision_index => /admin/custom_vision(.:format)
  // function(options)
  admin_custom_vision_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"custom_vision",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_external_api_webhook_logs => /admin/external_api_webhook_logs(.:format)
  // function(options)
  admin_external_api_webhook_logs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"external_api_webhook_logs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_fraction => /admin/fractions/:id(.:format)
  // function(id, options)
  admin_fraction_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"fractions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_fractions => /admin/fractions(.:format)
  // function(options)
  admin_fractions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"fractions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_group => /admin/groups/:id(.:format)
  // function(id, options)
  admin_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_groups => /admin/groups(.:format)
  // function(options)
  admin_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_location => /admin/locations/:id(.:format)
  // function(id, options)
  admin_location_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_locations => /admin/locations(.:format)
  // function(options)
  admin_locations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"locations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_main_batch_snapshot => /admin/main_batch_snapshots/:id(.:format)
  // function(id, options)
  admin_main_batch_snapshot_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"main_batch_snapshots",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_main_batch_snapshots => /admin/main_batch_snapshots(.:format)
  // function(options)
  admin_main_batch_snapshots_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"main_batch_snapshots",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_menu_categories => /admin/menu_categories(.:format)
  // function(options)
  admin_menu_categories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"menu_categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_menu_category => /admin/menu_categories/:id(.:format)
  // function(id, options)
  admin_menu_category_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"menu_categories",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_monitor => /admin/monitor(.:format)
  // function(options)
  admin_monitor_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"monitor",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_scheduler_run_reports => /admin/scheduler_run_reports(.:format)
  // function(options)
  admin_scheduler_run_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"scheduler_run_reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_setting => /admin/settings/:id(.:format)
  // function(id, options)
  admin_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_settings => /admin/settings(.:format)
  // function(options)
  admin_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_unit => /admin/units/:id(.:format)
  // function(id, options)
  admin_unit_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"units",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_units => /admin/units(.:format)
  // function(options)
  admin_units_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"units",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_users => /admin/users(.:format)
  // function(options)
  admin_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_web_request_logs => /admin/web_request_logs(.:format)
  // function(options)
  admin_web_request_logs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"web_request_logs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// alergens_product_attributes => /product_attributes/alergens(.:format)
  // function(options)
  alergens_product_attributes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"product_attributes",false],[2,[7,"/",false],[2,[6,"alergens",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_config => /api/config(.:format)
  // function(options)
  api_config_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"config",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_configurator_orders => /api/configurator/orders(.:format)
  // function(options)
  api_configurator_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"configurator",false],[2,[7,"/",false],[2,[6,"orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_configurator_prices => /api/configurator/prices(.:format)
  // function(options)
  api_configurator_prices_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"configurator",false],[2,[7,"/",false],[2,[6,"prices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_external_v1_menus => /api/external/v1/menus(.:format)
  // function(options)
  api_external_v1_menus_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"menus",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_external_v1_order => /api/external/v1/orders/:id(.:format)
  // function(id, options)
  api_external_v1_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_external_v1_orders => /api/external/v1/orders(.:format)
  // function(options)
  api_external_v1_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_external_v1_recipes => /api/external/v1/recipes(.:format)
  // function(options)
  api_external_v1_recipes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"recipes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_health => /api/health(.:format)
  // function(options)
  api_health_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"health",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_internal_attachment => /api/internal/attachments/:id/:attachable_type/:attachable_id(.:format)
  // function(id, attachable_type, attachable_id, options)
  api_internal_attachment_path: Utils.route([["id",true],["attachable_type",true],["attachable_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[3,"attachable_type",false],[2,[7,"/",false],[2,[3,"attachable_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_internal_menu => /api/internal/menus/:id(.:format)
  // function(id, options)
  api_internal_menu_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"menus",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_internal_product => /api/internal/products/:id(.:format)
  // function(id, options)
  api_internal_product_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_internal_products => /api/internal/products(.:format)
  // function(options)
  api_internal_products_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"products",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_internal_recipe => /api/internal/recipes/:id(.:format)
  // function(id, options)
  api_internal_recipe_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"recipes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_internal_recipes => /api/internal/recipes(.:format)
  // function(options)
  api_internal_recipes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"recipes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_internal_user => /api/internal/users/:id(.:format)
  // function(id, options)
  api_internal_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_internal_users => /api/internal/users(.:format)
  // function(options)
  api_internal_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_internal_value => /api/internal/values/:id(.:format)
  // function(id, options)
  api_internal_value_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"values",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_mobile_v1_auth_index => /api/mobile/v1/auth(.:format)
  // function(options)
  api_mobile_v1_auth_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"auth",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_mobile_v1_order => /api/mobile/v1/orders/:id(.:format)
  // function(id, options)
  api_mobile_v1_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_mobile_v1_register_and_auth_index => /api/mobile/v1/register_and_auth(.:format)
  // function(options)
  api_mobile_v1_register_and_auth_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"register_and_auth",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_service_v1_verifications => /api/service/v1/verifications(.:format)
  // function(options)
  api_service_v1_verifications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"service",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"verifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// available_time_slots_api_mobile_v1_orders => /api/mobile/v1/orders/available_time_slots(.:format)
  // function(options)
  available_time_slots_api_mobile_v1_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"available_time_slots",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// boiler_batch_status_api_internal_work_batch => /api/internal/work_batch/boiler_batch_status(.:format)
  // function(options)
  boiler_batch_status_api_internal_work_batch_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"work_batch",false],[2,[7,"/",false],[2,[6,"boiler_batch_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// book_time_slot_api_mobile_v1_orders => /api/mobile/v1/orders/book_time_slot(.:format)
  // function(options)
  book_time_slot_api_mobile_v1_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"book_time_slot",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// cancel_api_external_v1_order => /api/external/v1/orders/:id/cancel(.:format)
  // function(id, options)
  cancel_api_external_v1_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// cancel_manual_payment_order => /orders/:id/cancel_manual_payment(.:format)
  // function(id, options)
  cancel_manual_payment_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancel_manual_payment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cancel_manually_paid_payment_order => /orders/:id/cancel_manually_paid_payment(.:format)
  // function(id, options)
  cancel_manually_paid_payment_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancel_manually_paid_payment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cancel_processing_order_api_mobile_v1_orders => /api/mobile/v1/orders/cancel_processing_order(.:format)
  // function(options)
  cancel_processing_order_api_mobile_v1_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"cancel_processing_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// cancel_queued_order => /queued_orders/:id/cancel(.:format)
  // function(id, options)
  cancel_queued_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"queued_orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cancel_time_slot_api_mobile_v1_orders => /api/mobile/v1/orders/cancel_time_slot(.:format)
  // function(options)
  cancel_time_slot_api_mobile_v1_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"cancel_time_slot",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// cancel_timeslot_and_payment_api_mobile_v1_orders => /api/mobile/v1/orders/cancel_timeslot_and_payment(.:format)
  // function(options)
  cancel_timeslot_and_payment_api_mobile_v1_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"cancel_timeslot_and_payment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// cart_replenishment_orders => /replenishment_orders/cart(.:format)
  // function(options)
  cart_replenishment_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"replenishment_orders",false],[2,[7,"/",false],[2,[6,"cart",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// check_api_service_v1_authorization_index => /api/service/v1/authorization/check(.:format)
  // function(options)
  check_api_service_v1_authorization_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"service",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"authorization",false],[2,[7,"/",false],[2,[6,"check",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// clear_draft_order_api_mobile_v1_orders => /api/mobile/v1/orders/clear_draft_order(.:format)
  // function(options)
  clear_draft_order_api_mobile_v1_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"clear_draft_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// configurator => /configurator
  // function(options)
  configurator_path: Utils.route([], {}, [2,[7,"/",false],[6,"configurator",false]]),
// configurator.root => /configurator/
  // function(options)
  configurator_root_path: Utils.route([], {}, [2,[2,[7,"/",false],[6,"configurator",false]],[7,"/",false]]),
// confirm_manual_payment_order => /orders/:id/confirm_manual_payment(.:format)
  // function(id, options)
  confirm_manual_payment_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"confirm_manual_payment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// create_new_version_draft_recipe => /recipes/:id/create_new_version_draft(.:format)
  // function(id, options)
  create_new_version_draft_recipe_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"recipes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"create_new_version_draft",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// create_order_orders => /orders/create_order(.:format)
  // function(options)
  create_order_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"create_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// create_payment_method_api_mobile_v1_users => /api/mobile/v1/users/create_payment_method(.:format)
  // function(options)
  create_payment_method_api_mobile_v1_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"create_payment_method",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// current_day_active_menu_api_mobile_v1_menus => /api/mobile/v1/menus/current_day_active_menu(.:format)
  // function(options)
  current_day_active_menu_api_mobile_v1_menus_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"menus",false],[2,[7,"/",false],[2,[6,"current_day_active_menu",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// destroy_user_session => /users/sign_out(.:format)
  // function(options)
  destroy_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// details_product => /products/:id/details(.:format)
  // function(id, options)
  details_product_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// draft_order_api_mobile_v1_orders => /api/mobile/v1/orders/draft_order(.:format)
  // function(options)
  draft_order_api_mobile_v1_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"draft_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// dynamic_table => /dynamic_tables/:id(.:format)
  // function(id, options)
  dynamic_table_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dynamic_tables",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_admin_alergen => /admin/alergens/:id/edit(.:format)
  // function(id, options)
  edit_admin_alergen_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"alergens",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_cooking_mode => /admin/cooking_modes/:id/edit(.:format)
  // function(id, options)
  edit_admin_cooking_mode_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"cooking_modes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_currency => /admin/currencies/:id/edit(.:format)
  // function(id, options)
  edit_admin_currency_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currencies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_fraction => /admin/fractions/:id/edit(.:format)
  // function(id, options)
  edit_admin_fraction_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"fractions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_group => /admin/groups/:id/edit(.:format)
  // function(id, options)
  edit_admin_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_location => /admin/locations/:id/edit(.:format)
  // function(id, options)
  edit_admin_location_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_menu_category => /admin/menu_categories/:id/edit(.:format)
  // function(id, options)
  edit_admin_menu_category_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"menu_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_monitor => /admin/monitor/edit(.:format)
  // function(options)
  edit_admin_monitor_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"monitor",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_admin_unit => /admin/units/:id/edit(.:format)
  // function(id, options)
  edit_admin_unit_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"units",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_user => /admin/users/:id/edit(.:format)
  // function(id, options)
  edit_admin_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_product_category => /product_categories/:id/edit(.:format)
  // function(id, options)
  edit_product_category_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"product_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
  // function(id, options)
  edit_rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_recipe => /recipes/:id/edit(.:format)
  // function(id, options)
  edit_recipe_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"recipes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_stock => /stocks/:id/edit(.:format)
  // function(id, options)
  edit_stock_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"stocks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// enqueue_api_external_v1_order => /api/external/v1/orders/:id/enqueue(.:format)
  // function(id, options)
  enqueue_api_external_v1_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"enqueue",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// enter_edit_order_replenishment_orders => /replenishment_orders/enter_edit_order(.:format)
  // function(options)
  enter_edit_order_replenishment_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"replenishment_orders",false],[2,[7,"/",false],[2,[6,"enter_edit_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// feeders_stocks => /stocks/feeders(.:format)
  // function(options)
  feeders_stocks_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"stocks",false],[2,[7,"/",false],[2,[6,"feeders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// fractions_product_attributes => /product_attributes/fractions(.:format)
  // function(options)
  fractions_product_attributes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"product_attributes",false],[2,[7,"/",false],[2,[6,"fractions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// groups_product_attributes => /product_attributes/groups(.:format)
  // function(options)
  groups_product_attributes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"product_attributes",false],[2,[7,"/",false],[2,[6,"groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// historical_orders_api_mobile_v1_orders => /api/mobile/v1/orders/historical_orders(.:format)
  // function(options)
  historical_orders_api_mobile_v1_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"historical_orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// history_orders_orders => /orders/history_orders(.:format)
  // function(options)
  history_orders_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"history_orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// lockers_status_api_internal_work_batch => /api/internal/work_batch/lockers_status(.:format)
  // function(options)
  lockers_status_api_internal_work_batch_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"work_batch",false],[2,[7,"/",false],[2,[6,"lockers_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// main_batch_status_api_internal_work_batch => /api/internal/work_batch/main_batch_status(.:format)
  // function(options)
  main_batch_status_api_internal_work_batch_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"work_batch",false],[2,[7,"/",false],[2,[6,"main_batch_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// mark_as_delivered_order => /orders/:id/mark_as_delivered(.:format)
  // function(id, options)
  mark_as_delivered_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"mark_as_delivered",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// mark_as_manual_payment_api_mobile_v1_orders => /api/mobile/v1/orders/mark_as_manual_payment(.:format)
  // function(options)
  mark_as_manual_payment_api_mobile_v1_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"mark_as_manual_payment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// mark_as_ready_for_capture_api_mobile_v1_orders => /api/mobile/v1/orders/mark_as_ready_for_capture(.:format)
  // function(options)
  mark_as_ready_for_capture_api_mobile_v1_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"mark_as_ready_for_capture",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// mark_as_ready_for_capture_orders => /orders/mark_as_ready_for_capture(.:format)
  // function(options)
  mark_as_ready_for_capture_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"mark_as_ready_for_capture",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// mark_robot_as_reset_admin_settings => /admin/settings/mark_robot_as_reset(.:format)
  // function(options)
  mark_robot_as_reset_admin_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"mark_robot_as_reset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// menu_categories_api_internal_recipes => /api/internal/recipes/menu_categories(.:format)
  // function(options)
  menu_categories_api_internal_recipes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"recipes",false],[2,[7,"/",false],[2,[6,"menu_categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// menu_changed_api_internal_menus => /api/internal/menus/menu_changed(.:format)
  // function(options)
  menu_changed_api_internal_menus_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"menus",false],[2,[7,"/",false],[2,[6,"menu_changed",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// menu_index => /menu(.:format)
  // function(options)
  menu_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"menu",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// merge_api_internal_menus => /api/internal/menus/merge(.:format)
  // function(options)
  merge_api_internal_menus_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"menus",false],[2,[7,"/",false],[2,[6,"merge",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// merge_draft_to_sent_order_replenishment_order => /replenishment_orders/:id/merge_draft_to_sent_order(.:format)
  // function(id, options)
  merge_draft_to_sent_order_replenishment_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"replenishment_orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"merge_draft_to_sent_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_alergen => /admin/alergens/new(.:format)
  // function(options)
  new_admin_alergen_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"alergens",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_cooking_mode => /admin/cooking_modes/new(.:format)
  // function(options)
  new_admin_cooking_mode_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"cooking_modes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_currency => /admin/currencies/new(.:format)
  // function(options)
  new_admin_currency_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currencies",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_fraction => /admin/fractions/new(.:format)
  // function(options)
  new_admin_fraction_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"fractions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_group => /admin/groups/new(.:format)
  // function(options)
  new_admin_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_location => /admin/locations/new(.:format)
  // function(options)
  new_admin_location_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_menu_category => /admin/menu_categories/new(.:format)
  // function(options)
  new_admin_menu_category_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"menu_categories",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_monitor => /admin/monitor/new(.:format)
  // function(options)
  new_admin_monitor_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"monitor",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_unit => /admin/units/new(.:format)
  // function(options)
  new_admin_unit_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"units",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_user => /admin/users/new(.:format)
  // function(options)
  new_admin_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_product_category => /product_categories/new(.:format)
  // function(options)
  new_product_category_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"product_categories",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_rails_conductor_inbound_email_source => /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_source_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"sources",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_recipe => /recipes/new(.:format)
  // function(options)
  new_recipe_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"recipes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_stock => /stocks/new(.:format)
  // function(options)
  new_stock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"stocks",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_session => /users/sign_in(.:format)
  // function(options)
  new_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// open_api_external_v1_locker => /api/external/v1/lockers/:number/open(.:format)
  // function(number, options)
  open_api_external_v1_locker_path: Utils.route([["number",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"lockers",false],[2,[7,"/",false],[2,[3,"number",false],[2,[7,"/",false],[2,[6,"open",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// open_locker_queued_order => /queued_orders/:id/open_locker(.:format)
  // function(id, options)
  open_locker_queued_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"queued_orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"open_locker",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// orders => /orders(.:format)
  // function(options)
  orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// payment_methods_api_mobile_v1_users => /api/mobile/v1/users/payment_methods(.:format)
  // function(options)
  payment_methods_api_mobile_v1_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"payment_methods",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// place_order_replenishment_order => /replenishment_orders/:id/place_order(.:format)
  // function(id, options)
  place_order_replenishment_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"replenishment_orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"place_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// plate_containers => /plate_containers(.:format)
  // function(options)
  plate_containers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"plate_containers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// product => /products/:id(.:format)
  // function(id, options)
  product_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// product_categories => /product_categories(.:format)
  // function(options)
  product_categories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"product_categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// product_categories_stocks => /stocks/product_categories(.:format)
  // function(options)
  product_categories_stocks_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"stocks",false],[2,[7,"/",false],[2,[6,"product_categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// product_categories_with_products_stocks => /stocks/product_categories_with_products(.:format)
  // function(options)
  product_categories_with_products_stocks_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"stocks",false],[2,[7,"/",false],[2,[6,"product_categories_with_products",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// product_category => /product_categories/:id(.:format)
  // function(id, options)
  product_category_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"product_categories",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// product_columns_products => /products/product_columns(.:format)
  // function(options)
  product_columns_products_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[6,"product_columns",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// product_container_remove => /product_containers/:product_container_id/remove(.:format)
  // function(product_container_id, options)
  product_container_remove_path: Utils.route([["product_container_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"product_containers",false],[2,[7,"/",false],[2,[3,"product_container_id",false],[2,[7,"/",false],[2,[6,"remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// product_containers => /product_containers(.:format)
  // function(options)
  product_containers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"product_containers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// product_stocks => /stocks/product(.:format)
  // function(options)
  product_stocks_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"stocks",false],[2,[7,"/",false],[2,[6,"product",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// products => /products(.:format)
  // function(options)
  products_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"products",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// products_with_categories_products => /products/products_with_categories(.:format)
  // function(options)
  products_with_categories_products_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[6,"products_with_categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// projects_admin_custom_vision_index => /admin/custom_vision/projects(.:format)
  // function(options)
  projects_admin_custom_vision_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"custom_vision",false],[2,[7,"/",false],[2,[6,"projects",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_blob_representation => /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// rails_blob_representation_proxy => /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_proxy_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
  // function(id, options)
  rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_incinerate => /rails/conductor/action_mailbox/:inbound_email_id/incinerate(.:format)
  // function(inbound_email_id, options)
  rails_conductor_inbound_email_incinerate_path: Utils.route([["inbound_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"incinerate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_reroute => /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
  // function(inbound_email_id, options)
  rails_conductor_inbound_email_reroute_path: Utils.route([["inbound_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"reroute",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_sources => /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
  // function(options)
  rails_conductor_inbound_email_sources_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"sources",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_emails => /rails/conductor/action_mailbox/inbound_emails(.:format)
  // function(options)
  rails_conductor_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_direct_uploads => /rails/active_storage/direct_uploads(.:format)
  // function(options)
  rails_direct_uploads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"direct_uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_disk_service => /rails/active_storage/disk/:encoded_key/*filename(.:format)
  // function(encoded_key, filename, options)
  rails_disk_service_path: Utils.route([["encoded_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_mailgun_inbound_emails => /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
  // function(options)
  rails_mailgun_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mailgun",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"mime",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_mandrill_inbound_emails => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_mandrill_inbound_health_check => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_health_check_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_postmark_inbound_emails => /rails/action_mailbox/postmark/inbound_emails(.:format)
  // function(options)
  rails_postmark_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"postmark",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_relay_inbound_emails => /rails/action_mailbox/relay/inbound_emails(.:format)
  // function(options)
  rails_relay_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"relay",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_sendgrid_inbound_emails => /rails/action_mailbox/sendgrid/inbound_emails(.:format)
  // function(options)
  rails_sendgrid_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"sendgrid",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_service_blob => /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// rails_service_blob_proxy => /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_proxy_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// recipe => /recipes/:id(.:format)
  // function(id, options)
  recipe_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"recipes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// recipes => /recipes(.:format)
  // function(options)
  recipes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"recipes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// recipes_columns_recipes => /recipes/recipes_columns(.:format)
  // function(options)
  recipes_columns_recipes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"recipes",false],[2,[7,"/",false],[2,[6,"recipes_columns",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// refresh_token_microsoft_index => /microsoft/refresh_token(.:format)
  // function(options)
  refresh_token_microsoft_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"microsoft",false],[2,[7,"/",false],[2,[6,"refresh_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// replenishment_order => /replenishment_orders/:id(.:format)
  // function(id, options)
  replenishment_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"replenishment_orders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// replenishment_order_pdf_replenishment_order => /replenishment_orders/:id/replenishment_order_pdf(.:format)
  // function(id, options)
  replenishment_order_pdf_replenishment_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"replenishment_orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"replenishment_order_pdf",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// replenishment_orders => /replenishment_orders(.:format)
  // function(options)
  replenishment_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"replenishment_orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// reports => /reports(.:format)
  // function(options)
  reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// reset_queue_orders => /orders/reset_queue(.:format)
  // function(options)
  reset_queue_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"reset_queue",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reset_robot_api_internal_work_batch => /api/internal/work_batch/reset_robot(.:format)
  // function(options)
  reset_robot_api_internal_work_batch_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"work_batch",false],[2,[7,"/",false],[2,[6,"reset_robot",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// root => /
  // function(options)
  root_path: Utils.route([], {}, [7,"/",false]),
// roots_product_categories => /product_categories/roots(.:format)
  // function(options)
  roots_product_categories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"product_categories",false],[2,[7,"/",false],[2,[6,"roots",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rswag_api => /api-docs
  // function(options)
  rswag_api_path: Utils.route([], {}, [2,[7,"/",false],[6,"api-docs",false]]),
// rswag_ui => /api-docs
  // function(options)
  rswag_ui_path: Utils.route([], {}, [2,[7,"/",false],[6,"api-docs",false]]),
// search_products => /products/search(.:format)
  // function(options)
  search_products_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// select_options_product_categories => /product_categories/select_options(.:format)
  // function(options)
  select_options_product_categories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"product_categories",false],[2,[7,"/",false],[2,[6,"select_options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// set_default_payment_method_api_mobile_v1_users => /api/mobile/v1/users/set_default_payment_method(.:format)
  // function(options)
  set_default_payment_method_api_mobile_v1_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"set_default_payment_method",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// show_low_level_recipe => /recipes/:id/low_level(.:format)
  // function(id, options)
  show_low_level_recipe_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"recipes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"low_level",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// stock => /stocks/:id(.:format)
  // function(id, options)
  stock_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"stocks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// stocks => /stocks(.:format)
  // function(options)
  stocks_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"stocks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// stripe_3d_secure_callback_users => /users/stripe_3d_secure_callback(.:format)
  // function(options)
  stripe_3d_secure_callback_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"stripe_3d_secure_callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// stripe_set_card_as_default_users => /users/stripe_set_card_as_default(.:format)
  // function(options)
  stripe_set_card_as_default_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"stripe_set_card_as_default",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// system_status_api_internal_work_batch => /api/internal/work_batch/system_status(.:format)
  // function(options)
  system_status_api_internal_work_batch_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"work_batch",false],[2,[7,"/",false],[2,[6,"system_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// units_product_attributes => /product_attributes/units(.:format)
  // function(options)
  units_product_attributes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"product_attributes",false],[2,[7,"/",false],[2,[6,"units",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// update_all_dynamic_tables => /dynamic_tables/update_all(.:format)
  // function(options)
  update_all_dynamic_tables_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dynamic_tables",false],[2,[7,"/",false],[2,[6,"update_all",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// update_draft_order_api_mobile_v1_orders => /api/mobile/v1/orders/update_draft_order(.:format)
  // function(options)
  update_draft_order_api_mobile_v1_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mobile",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"update_draft_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// update_positions_dynamic_tables => /dynamic_tables/update_positions(.:format)
  // function(options)
  update_positions_dynamic_tables_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dynamic_tables",false],[2,[7,"/",false],[2,[6,"update_positions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// update_positions_product_categories => /product_categories/update_positions(.:format)
  // function(options)
  update_positions_product_categories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"product_categories",false],[2,[7,"/",false],[2,[6,"update_positions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// update_rails_disk_service => /rails/active_storage/disk/:encoded_token(.:format)
  // function(encoded_token, options)
  update_rails_disk_service_path: Utils.route([["encoded_token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_table_positions_product_categories => /product_categories/update_table_positions(.:format)
  // function(options)
  update_table_positions_product_categories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"product_categories",false],[2,[7,"/",false],[2,[6,"update_table_positions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_session => /users/sign_in(.:format)
  // function(options)
  user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// within_month_api_internal_menus => /api/internal/menus/:month(.:format)
  // function(month, options)
  within_month_api_internal_menus_path: Utils.route([["month",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"menus",false],[2,[7,"/",false],[2,[3,"month",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// write_low_level_recipe => /recipes/:id/execute(.:format)
  // function(id, options)
  write_low_level_recipe_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"recipes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"execute",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Object.assign({
        "default": routes
      }, routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  } else if (typeof module !== "undefined" && module !== null) {
    try {
      module.exports = result;
    } catch (error1) {
      error = error1;
      if (error.name !== 'TypeError') {
        throw error;
      }
    }
  } else {
    Utils.namespace(this, null, result);
  }

  return result;

}).call(this);

