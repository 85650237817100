import React, { useEffect, useState } from 'react'
import { Box, Drawer } from '@mui/material'
import moment from 'moment'
import Icon from '../shared/icon'
import { t } from '../../i18n'
import axios from 'axios'
import { ReplenishmentOrder } from './types'
import ReplenishmentHistoryOrder from './ReplenishmentHistoryOrder'

const scope = "replenishment.sidebar.history"

const ReplenishmentOrderRow = ({ order, editable = false, enterReplenishmentEditView = () => {}, closeSidebar = () => {},
  openCartSidebar = (open) => {}, setSelectedOrder = (order) => {}}) => {

  const orderItemsCount = () => {
    return order.productReplenishments.length + order.plateReplenishments.length
  }

  const orderStatus = () => {
    switch(order.state) {
      case 'finished':
        return t('state_completed', { scope, deliveryDate: moment(order.deliveryDate)?.format('M/D/YYYY') })
      case 'draft':
        return t(order.editableOrderId ? 'state_editing' : 'state_open', { scope })
      case 'sent':
        return t('state_sent', { scope })
      case 'closed':
        return t('state_closed', { scope })
    }
  }

  const editOrder = () => {
    if(order.state == 'draft' && order.editableOrderId) {
      openCartSidebar(true)
    } else {
      enterReplenishmentEditView()
      openCartSidebar(true)
    }
  }

  const statusColor = () => {
    if(order.state == 'closed') {
      return 'red'
    } else if(order.state == 'draft') {
      if(order.editableOrderId) {
        return 'yellow'
      }
    }
    return 'green'
  }

  return (
    <div className="order-info-wrapper" onClick={() => setSelectedOrder(order)}>
      <div className="order-info">
        <span className="order-id">{t('order_name', { scope, orderId: order.id})}</span>
        <div> <span>Created at: {moment(order.createdAt).format('M/D/YYYY')}</span> </div>
        <div> <span>{orderItemsCount()} {t(orderItemsCount() > 1 ? 'items' : 'item', { scope })}</span> </div>
        <div className="order-status">
          <span>{t('status', { scope })}</span>
          <span className={statusColor()}> {orderStatus()}</span>
        </div>
        {order.state == 'sent' || (order.state == 'draft' && !!order.editableOrderId) &&
          <div>
            <span>{t('planned_delivery', { scope })}</span>
            <span className='delivery-date'>{moment(order.deliveryDate)?.format('M/D/YYYY')}</span>
          </div>
        }
      </div>
      {editable && <Icon name="editIcon" className="edit-icon" onClick={editOrder}/> }
    </div>
  )
}

export const ReplenishmentHistorySidebar = ({ sidebarOpen, replenishmentOrder, closeSidebar, enterReplenishmentEditView, openCartSidebar }) => {

  const [ordersHistory, setOrdersHistory] = useState<ReplenishmentOrder[]>([])
  const [historyPage, setHistoryPage] = useState(1)
  const [totalHistoryPages, setTotalHistoryPages] = useState(0)
  const [selectedOrder, setSelectedOrder] = useState(null)

  useEffect(() => {
    fetchOrdersHistory()
  }, [])


  const fetchOrdersHistory = () => {
    axios.get(`/replenishment_orders?states[]=finished&page=${historyPage}&format=json`).then(({data}) => {
      setOrdersHistory(data.replenishment_orders)
      setTotalHistoryPages(data.pagesCount)
    })
  }

  const nextHistoryPage = () => {
    setHistoryPage(historyPage + 1)
    axios.get(`/replenishment_orders?states[]=finished&page=${historyPage}&format=json`).then(({data}) => {
      setOrdersHistory([...ordersHistory, ...data.replenishment_orders])
    })
  }

  const displayActiveOrder = () => {
    return replenishmentOrder.state == 'sent' || (replenishmentOrder.state == 'draft' && !!replenishmentOrder.editableOrderId)
  }

  return (
    <div>
      <Drawer
        className='replenishment-sidebar'
        anchor='right'
        open={sidebarOpen}
        onClose={closeSidebar}
        style={{zIndex: '2 !important'}}
      >
       <Box
          sx={{ width: 400, padding: '20px 15px', borderRadius: '10px 0 0 10px', zIndex: '2 !important' }}
          role="presentation"
        >
          <div className="replenishment-sidebar-content">
          {!selectedOrder &&
            <>
              <div className="sidebar-top">
                <div className='sidebar-header'>
                  <span className='sidebar-heading'>{t('my_orders', { scope })}</span>
                  <Icon name='close' className="close-icon" onClick={closeSidebar}/>
                </div>
              </div>
              <div className="orders-history">
                {displayActiveOrder() &&
                  <div>
                    <br/>
                    <span className='sidebar-heading'>{t('active_order', { scope })}</span>
                      <ReplenishmentOrderRow
                        order={replenishmentOrder}
                        editable={true}
                        setSelectedOrder={setSelectedOrder}
                        enterReplenishmentEditView={enterReplenishmentEditView}
                        closeSidebar={closeSidebar}
                        openCartSidebar={openCartSidebar}
                        />
                  </div>
                }
                {!!ordersHistory.length &&
                  <div style={{marginTop: '35px'}}>
                    <span className='sidebar-heading'>{t('orders_history', { scope })}</span>
                    {ordersHistory.map(order => { return (
                      <ReplenishmentOrderRow order={order} key={order.id} setSelectedOrder={setSelectedOrder}/>
                    ) })}
                  </div>
                }
              </div>

              {totalHistoryPages > historyPage &&
                <div className="replenishment-buttons">
                  <button className="btn btn-secondary mt-3" onClick={nextHistoryPage}>{t('show_more', { scope })}</button>
                </div>
              }
            </>
          }
          {selectedOrder && <ReplenishmentHistoryOrder replenishmentOrder={selectedOrder} closeSidebar={() => setSelectedOrder(null)}/>}
          </div>
        </Box>
      </Drawer>
    </div>
  )
}
