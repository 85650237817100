import React from 'react'
import Select from 'react-select'
import find from 'lodash/find'
import { Formik, yupToFormErrors } from 'formik'
import { validationSchema } from './validationSchema'
import { User } from '../../models/User'

const UsersForm = (props) => (
  <Formik
    initialValues={User(props)}
    validationSchema={validationSchema}
    validate={values => validationSchema.validate(values)}
    onSubmit={(values, { setStatus, setSubmitting }) =>
      props.onSubmit(values, (success, errorData) => {
        if (!success) {
          let status = {}
          const fields = Object.getOwnPropertyNames(errorData.full_errors)
          for (let key in fields) {
            status[`${fields[key]}_error`] = errorData.full_errors[fields[key]].join(', ')
          }
          setStatus(status)
        }
        setSubmitting(false)
      })
    }
  >
    {({
      values,
      errors,
      touched,
      status,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      setStatus,
    }) => {
      const enhancedHandleChange = (e) => {
        let newStatus = Object.assign({}, status) // could be stale?
        newStatus[`${e.target.name}_error`] = ""
        setStatus(newStatus)
        handleChange(e)
      }

      const renderField = (label, name, type, placeHolder, autoComplete = 'off') => {
        const errorsPresent = errors[name] && touched[name] || status && status[`${name}_error`]
        return (
          <div className="form-group">
            <label>{label}</label>
            <input
              id={name}
              name={name}
              type={type}
              onBlur={handleBlur}
              value={values[name]}
              placeholder={placeHolder}
              autoComplete={autoComplete}
              onChange={enhancedHandleChange}
              className={`form-control inverted ${errorsPresent && 'is-invalid'}`}
            />
            {errorsPresent && <div className="invalid-feedback">{errors[name] || status && status[`${name}_error`]}</div>}
          </div>
        )
      }

      const renderSelect = (label, name) => {
        const isAnonymousRole = values[name] === 'anonymous'
        let roles = ['admin', 'seller', 'terminal']

        if (isAnonymousRole) {
          roles.push('anonymous')
        }

        const selectOptions = roles.map( role => ({ label: role, value: role }) )

        return (
          <div className="form-group">
            <label>{label}</label>
            <Select classNamePrefix='react-select'
              className={`react-select inverted ${touched[name] && errors[name] && 'is-invalid'}`}
              options={selectOptions}
              placeholder='Select role'
              id={name}
              name={name}
              onBlur={handleBlur}
              value={find(selectOptions, opt => opt.value === values[name])}
              onChange={(e) => enhancedHandleChange({ target: { name: 'role', value: e.value } })}
              disabled={isAnonymousRole}
            />
            {errors[name] && touched[name] && <div className="invalid-feedback">{errors[name]}</div>}
          </div>
        )
      }

      return (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <div className='row mb-3'>
            <div className='col'>
              <p className='form-section-title'>User data</p>
              {renderField('Email address', 'email', 'email', 'Enter email')}
              {renderField('First name', 'first_name', 'text', 'Enter first name')}
              {renderField('Last name', 'last_name', 'text', 'Enter last name')}
            </div>
            <div className='col'>
              <p className='form-section-title'>Permissions</p>
              {renderSelect('Role', 'role')}
              {renderField('Password', 'password', 'password', 'Enter password', 'new-password')}
              {renderField('Password confirmation', 'password_confirmation', 'password', 'Password confirmation', 'new-password')}
            </div>
          </div>

          <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Submit</button>
          <button className="btn btn-link" onClick={() => window.history.back()}>Back to list</button>
        </form>
      )
    }}
  </Formik>
)

export default UsersForm
