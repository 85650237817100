import React, { Component } from 'react'
import axios from 'axios'
import { Table, TableBody } from './DynamicTable'
import { extractDataErrors } from '../shared/extractDataErrors'
import EmptyTablePlaceholder from '../shared/EmptyTablePlaceholder'
import { t } from '../../i18n'

const findCategory = (categories, id) => {
  const filtered = categories.filter(c => c.id == id)
  if (filtered.length === 0) {
    return findCategory(categories.flatMap(c => c.children), id)
  } else {
    return filtered
  }
}

class ProductCategory extends Component {
  state = {
    selectedCategory: null,
    possibleColumns: [],
    settingsOpen: false
  }

  static getDerivedStateFromProps(props, state) {
    if (props.categories.length && !state.selectedCategory) {
      const categoryId = props.match.params.category
      const categories = props.categories
      let selectedCategory = categories.filter(c => c.id === categoryId)
      if (!selectedCategory.length) {
        selectedCategory = findCategory(categories, categoryId)
      }
      props.setSelectedCategory(selectedCategory[0])
      return { ...state, selectedCategory: selectedCategory[0] }
    } else {
      return state
    }
  }

  componentDidMount() {
    const categoryId = this.props.match.params.category
    this.props.fetchCategoryChildren(categoryId)
    this.fetchColumnNames()
    document.addEventListener("mousedown", this.handleClickOutside.bind(this))
  }

  componentDidUpdate(prevProps) {
    const categoryId = this.props.selectedCategory?.id || this.props.match.params.category
    if (this.props.refresh) {
      this.props.fetchCategoryChildren(categoryId)
      this.props.setRefreshToFalse()
    }
  }

  handleClickOutside(e) {
    if (document.getElementById('settings-container') && !document.getElementById('settings-container').contains(e.target)) {
      this.setState({ settingsOpen: false })
    }
  }

  fetchColumnNames() {
    axios.get(Routes.product_columns_products_path({ format: 'json' })).then(res => {
      this.setState({ possibleColumns: res.data })
    }).catch(err => {
      this.props.setErrorMessages(extractDataErrors(err).full_errors)
    })
  }

  toggleSettings = () => this.setState({ settingsOpen: !this.state.settingsOpen })
  handleSettingChange = (record, checked) => {
    axios.put(Routes.dynamic_table_path(record.id, { format: 'json' }), { record: { selected: checked } }).then(res => {
      this.fetchColumnNames()
      this.props.setSuccessMessage('Settings updated')
    }).catch(err => {
      this.props.setErrorMessages(extractDataErrors(err).full_errors)
    })
  }

  updateAll = (selected) => {
    axios.post(Routes.update_all_dynamic_tables_path({ format: 'json' }), { selected: selected, ids: this.state.possibleColumns.filter(column => column.mandatory == false).map(c => c.id) }).then(res => {
      this.fetchColumnNames()
      this.props.setSuccessMessage('Settings updated')
    }).catch(err => {
      this.props.setErrorMessages(extractDataErrors(err).full_errors)
    })
  }

  updatePositions = (columns) => {
    this.setState({ possibleColumns: columns })
    axios.post(Routes.update_positions_dynamic_tables_path({ format: 'json' }), { columns }).then(res => {
      this.props.setSuccessMessage('Settings updated')
    }).catch(err => {
      this.props.setErrorMessages(extractDataErrors(err).full_errors)
    })
  }

  selectAll = () => this.updateAll(true)
  clearAll = () => this.updateAll(false)

  render() {
    const { settingsOpen, possibleColumns } = this.state

    if(!this.props.categoryChildren.length) {
      return <EmptyTablePlaceholder heading={t('product.no_records_heading')}/>
    }

    return (
      <Table
        edit={this.props.edit}
        possibleColumns={possibleColumns}
        toggleSettings={this.toggleSettings}
        handleSettingChange={this.handleSettingChange}
        settingsOpen={settingsOpen}
        selectAll={this.selectAll}
        clearAll={this.clearAll}
        updatePositions={this.updatePositions}
        updateTablePositions={this.props.updateTablePositions}
      >
        <TableBody
          edit={this.props.edit}
          categoryChildren={this.props.categoryChildren}
          setObjectForEdit={this.props.setObjectForEdit}
          setObjectForShow={this.props.setObjectForShow}
          destroy={this.props.destroy}
          toggleTableRow={this.props.toggleTableChilds}
          possibleColumns={this.state.possibleColumns}
        />
      </Table>
    )
  }
}

export default ProductCategory
