import React, {useContext} from 'react'
import Tooltip from '@mui/material/Tooltip'
import includes from 'lodash/includes'
import map from 'lodash/map'
import split from 'lodash/split'
import moment from 'moment'
import { transliterate as tr } from 'transliteration'
import { ProductContainer } from './types'
import { WeightScaleContext } from '../shared/contexts'
import Icon from '../shared/icon'
import { t } from '../../i18n'

const STATUS_OK = 'ok'
const STATUS_WARNING = 'warning'
const STATUS_ALERT = 'alert'

const scope = 'product_container'

const ProductContainerSlot: React.FC<{
  slotNumber: number,
  humanSlotNumber: number,
  currentDate: moment.Moment,
  container: ProductContainer,
  expandContainer: Function,
  searchQuery: string,
  currentProductCategoryId: number,
  isLiquid?: boolean,
  replenished?: boolean,
  replenishmentView?: boolean,
  orderSent: boolean
}> = ({
  slotNumber, humanSlotNumber, currentDate, container, expandContainer, searchQuery, currentProductCategoryId, isLiquid, replenished, replenishmentView, orderSent
}) => {
  const weightScale = useContext(WeightScaleContext);
  const weightUnit = weightScale == 'metric' ? 'kg' : 'lbs'

  const expandProductContainer = (slotNumber, humanSlotNumber, isLiquid) => {
    if(!container && replenishmentView && orderSent) {
      return
    }
    expandContainer(slotNumber, humanSlotNumber, isLiquid)
  }

  if (!container) {
    return (
      <div
        className={`container-slot product empty ${(replenished && !orderSent) ? 'replenished' : ''}`}
        onClick={() => expandProductContainer(slotNumber, humanSlotNumber, isLiquid)}
      >
        <span className='container-edge top left'></span>
        <span className='container-edge top right'></span>
        {(orderSent && replenishmentView && replenished) && <span className='replenishment-sent-corner-status'></span>}
        <span className='container-edge bottom left'></span>
        <span className='container-edge bottom right'></span>
        <div className='container-info'>
          <div className='product-name'/>
          <div className='empty-container-slot-number'>
            #{humanSlotNumber}
          </div>
        </div>
        <div className='add-button'>
          <i className='fa fa-plus-circle'/>
          <span style={{fontSize:"12px"}}>{t('replace', { scope })}</span>
        </div>
      </div>
    )
  }

  const {
    product, expiration_date, initial_amount, used_amount, show_alert, remaining_amount,
    remaining_amount_percent, remaining_amount_status, days_until_expiration, expiration_date_status
  } = container

  const formattedExpirationDate = moment(expiration_date).format('DD.MM.YYYY.')

  let expirationText: string
  if (days_until_expiration < 0) {
    expirationText = `expired`
  } else if (includes([STATUS_ALERT, STATUS_WARNING], expiration_date_status)) {
    expirationText = `${days_until_expiration} days left`
  } else {
    expirationText = formattedExpirationDate
  }

  const matchesFilters = () => {
    const trSearchQuery = tr(searchQuery.toLowerCase())
    const searchingByQuery = trSearchQuery.length > 0
    const trProductName = tr(product.name.toLowerCase())
    if (searchingByQuery && !includes(trProductName, trSearchQuery)) return false

    const searchingByCategory = !!currentProductCategoryId
    if(searchingByCategory) {
        const category = product.product_category
        const categoryAncestorIds = map(split(category.ancestry, '/'), idStr => parseInt(idStr))
        const categoryAncestryWithSelfIds = [...categoryAncestorIds, category.id]
        if (!includes(categoryAncestryWithSelfIds, currentProductCategoryId)) return false
    }

    return searchingByQuery || searchingByCategory
  }

  return (
    <div
      data-number={container.slot_number}
      className={`container-slot product ${matchesFilters() ? 'highlighted' : ''} ${(replenished && !orderSent) ? 'replenished' : ''}`}
      onClick={() => expandProductContainer(slotNumber, humanSlotNumber, isLiquid)}
    >
      <span className='container-edge top left'></span>
      <span className='container-edge top right'></span>

      {show_alert && !(replenished && replenishmentView) && <span className='container-danger'></span>}
      {(orderSent && replenishmentView && replenished) && <span className='replenishment-sent-corner-status'></span>}

      <div className='container-info'>
        <div className='product-name'>{product.name}</div>
        {!(replenished && replenishmentView) &&
          <Tooltip title={`${Math.round(remaining_amount_percent)}% / ${remaining_amount}${weightUnit} left`} placement='top' arrow>
            <div className={`amount-bar status-${remaining_amount_status}`}>
              <div className='current-amount-bar' style={{ width: `${remaining_amount_percent}%` }}></div>
            </div>
          </Tooltip>
        }
        <div className='d-flex justify-content-between align-items-center mt-2'>
          <div className='container-slot-number'>
              {`#${humanSlotNumber}`}
          </div>
          {(replenished && replenishmentView && !orderSent)
          ? <div className="product-replenished-icon">
              <Icon name="replenishedProduct"/>
            </div>
          : <div className={`product-expiration-date status-${expiration_date_status}`}>
              {expirationText}
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default ProductContainerSlot
