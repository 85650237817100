import React, { PureComponent, Fragment } from 'react'
import axios from 'axios'
import forEach from 'lodash/forEach'
import RecipeEditForm from './RecipeEditForm'
import RecipeTasks from './recipe_tasks'
import Loading from '../shared/loading'
import FlashNotification, { setFlashMessage } from '../shared/flashNotification'
import SectionTitle from '../shared/sectionTitle'
import { PORTION_TYPE_BOWL } from '../shared/recipePortionTypes'
import { t } from '../../i18n'
import {
  CookingMode,
  ProductCategoryTree,
  RecipeTask,
  Attachment,
  MenuCategory,
  RecipeConfigurable,
  Currency
} from './types'

interface Props {
  id?: boolean,
  cooking_modes: CookingMode[],
  categorized_products: ProductCategoryTree[],
  currency: Currency,
  weight_scale: string
}

interface State {
  formChanged: boolean,
  loading: boolean,
  saveText: string,
  saveSuccessful: boolean,
  flashOpen: boolean,
  flashType: string,
  flashMessage: string,
  errorMessages: any,
  is_draft: boolean,
  id: number,
  price: string,
  preparation_time: string,
  attachment: Attachment | null,
  portion_type: string,
  imageWithinForm: string | null,
  imageUrl: string | null,
  name: string,
  tasks: Record<string, RecipeTask[]>,
  menuCategoriesOptions: MenuCategory[],
  menu_categories: MenuCategory[],
  recipeConfigurables: RecipeConfigurable[]
}

const initialState = {
  formChanged: false,
  loading: false,
  saveText: null,
  saveSuccessful: false,
  errorMessages: {},
  flashOpen: false,
  flashType: '',
  flashMessage: '',
  is_draft: false,
  portion_type: PORTION_TYPE_BOWL,
  id: null,
  price: '',
  preparation_time: '',
  attachment: null,
  imageWithinForm: null,
  imageUrl: null,
  name: '',
  tasks: {
    boiler_tasks: [],
    solid_topping_tasks: [],
    liquid_topping_tasks: [],
  },
  menuCategoriesOptions: null,
  menu_categories: [],
  recipeConfigurables: null
}

class RecipesEditScreen extends PureComponent<Props, State> {
  state = initialState
  setFlashMessage: Function

  constructor(props: Props) {
    super(props)

    this.setFlashMessage = setFlashMessage.bind(this)
  }

  componentDidMount = () => {
    this.props.id && this.loadPage()
    this.loadMenuCategories()

    // Notify about unsaved changes when leaving the page
    document.body.setAttribute('data-turbolinks', 'false') // Disable turbolinks to fix 'beforeunload' event
    window.addEventListener('beforeunload', (e) => {
      if (this.state.formChanged) {
        e.preventDefault() // Firefox
        e.returnValue = '' // Chrome
      }
    })
  }

  startLoading = () => this.setState({ loading: true })
  stopLoading = () => this.setState({ loading: false })

  loadPage = () => {
    this.startLoading()
    axios.get(
      `/api/internal/recipes/${this.props.id}`
    ).then(({ data }) => {
      this.stopLoading()
      this.setState(this.recipe(data))
      this.setStepNumbers(this.state.tasks)
    })
  }

  loadMenuCategories = () => {
    this.startLoading()
    axios.get('/api/internal/recipes/menu_categories').then(res => {
      this.setState({ menuCategoriesOptions: res.data.map(mc => ({ value: mc.id, label: mc.name })) })
      this.stopLoading()
    }).catch(err => {
      this.stopLoading()
    })
  }

  recipe = ({ id, name, price, preparation_time, attachment, recipe_tasks, is_draft, menu_categories, portion_type }) => ({
    id,
    name,
    price,
    attachment,
    preparation_time,
    is_draft,
    tasks: recipe_tasks,
    imageWithinForm: null,
    imageUrl: null,
    loading: false,
    menu_categories: menu_categories,
    formChanged: false,
    portion_type
  })

  url = () => {
    const { id } = this.state
    return id
      ? `/api/internal/recipes/${id}`
      : '/api/internal/recipes'
  }

  saveRecipe = () =>  {
    const { name, tasks, price, preparation_time, imageWithinForm, id, menu_categories, portion_type } = this.state
    const action = id ? 'patch' : 'post'
    const data = new FormData()
    data.append('recipe[name]', name)
    data.append('recipe[tasks]', JSON.stringify(tasks))
    data.append('recipe[price]', price)
    data.append('recipe[preparation_time]', preparation_time)
    data.append('recipe[portion_type]', portion_type)
    if (menu_categories.length > 0) {
      menu_categories.forEach(menuCategoryId =>
        data.append('recipe[menu_category_ids][]', menuCategoryId)
      )
    } else {
      data.append('recipe[menu_category_ids][]', '')
    }

    if (imageWithinForm) {
      const recipeEditForm = this.refs.recipeEditForm as any
      data.append('recipe[image]', recipeEditForm.refs.formik.refs.imageWithinForm.files[0])
    }

    this.startLoading()
    axios[action](this.url(), data, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => {
      this.stopLoading()
      this.setState({ id: data.id, formChanged: false })
      history.replaceState({}, null, `/recipes/${data.id}/edit`)
      this.loadMenuCategories()
      this.setFlashMessage('success', 'Save was successful')
    }).catch(({ response: { data: { full_errors } } }) => {
      this.stopLoading()

      if (full_errors?.base) {
        this.setFlashMessage('danger', full_errors.base.join(', ')) // errors regarding recipe ingredients
      } else if (typeof full_errors === 'object') {
        const errors = Object.keys(full_errors).map(key => Object.values(full_errors[key])).join(', ')
        this.setFlashMessage('danger', errors)
      } else {
        this.setFlashMessage('danger', 'Unexpected error')
      }
      this.setState({errorMessages: full_errors})
    })
  }

  removeAttachment = () => this.setState({ attachment: null, imageWithinForm: null, imageUrl: null })

  setStepNumbers = (steps) => {
    let tasks = {...this.state.tasks}

    forEach(Object.entries(steps), ([stepName, stepTasks]) => {
      let groupedTaskCounter = 0
      // Refresh task number and group number
      forEach(stepTasks, (task, index) => {
        task.task_number = index + 1
        if(task.recipe_task_products?.length > 1) {
          task.group_number = ++groupedTaskCounter
        }
      })
      tasks[stepName] = stepTasks
    })

    this.setState({ tasks })
  }

  fixErrorState = (field) => {
    if(this.state.errorMessages[field]) {
      this.setState({ errorMessages: { ...this.state.errorMessages, [field]: null } })
    }
  }

  renderActions = () => (
    <Fragment>
      <a href='/recipes' className='btn btn-secondary mr-2'>
        Back
      </a>
      {this.state.id &&
        <Fragment>
          <a className='btn btn-secondary mr-2' href={`/recipes/${this.state.id}/low_level`}>
            Details
          </a>
          {!this.state.is_draft &&
            <a
              className='btn btn-secondary mr-2'
              href={`/recipes/${this.state.id}/create_new_version_draft`}
              data-method='post'
              data-confirm='Are you sure?'
            >
              Create new version
            </a>
          }
        </Fragment>
      }
      <button type='button' className='btn btn-primary' onClick={this.saveRecipe} data-action='save-recipe'>
        Save
      </button>
    </Fragment>
  )

  renderNotification = () => {
    const { saveText, saveSuccessful } = this.state
    return (
      <div
        className={`alert alert-${saveSuccessful ? 'success' : 'danger'}`}
        style={{ position: 'absolute', visibility: saveText ? 'visible' : 'hidden' }}
      >
        {saveText}
      </div>
    )
  }

  renderHeader = () => {
    const actionName = this.props.id ? 'Edit' : 'New'

    return (
      <Fragment>
        <div className='mb-3 d-flex justify-content-between align-items-center'>
          <SectionTitle rows={[actionName, 'Recipe']} />
          <div>{this.renderActions()}</div>
        </div>
        {this.renderNotification()}
      </Fragment>
    )
  }

  handleChange = (name: string, value: any) => {
    this.setState({ formChanged: true, [name]: value } as any)
  }

  renderRecipeEditForm = () => (
    <RecipeEditForm
      id={this.state.id}
      ref='recipeEditForm'
      name={this.state.name}
      price={this.state.price}
      preparation_time={this.state.preparation_time}
      attachment={this.state.attachment}
      portion_type={this.state.portion_type}
      removeAttachment={this.removeAttachment}
      imageWithinForm={this.state.imageWithinForm}
      imageUrl={this.state.imageUrl}
      handleChange={this.handleChange}
      menu_categories={this.state.menu_categories}
      menuCategoriesOptions={this.state.menuCategoriesOptions}
      currency={this.props.currency}
      errorMessages={this.state.errorMessages}
      fixErrorState={this.fixErrorState}/>
  )

  renderFlashNotification = () => this.state.flashOpen && (
    <FlashNotification
      type={this.state.flashType}
      message={this.state.flashMessage}
      onClose={() => this.setState({ flashOpen: false })}
    />
  )

  renderLoading = () => this.state.loading && <Loading />

  render = () => (
    <div>
      {this.renderHeader()}
      <div className='card card-robis'>
        <p className='form-section-title'>{t('recipes.recipe_page')}</p>
        {this.renderRecipeEditForm()}
        <div className='card-divider'></div>
        <RecipeTasks
          tasks={this.state.tasks}
          cookingModes={this.props.cooking_modes}
          categorizedProducts={this.props.categorized_products}
          updateTasks={this.setStepNumbers}
          weightScale={this.props.weight_scale}
        />
        {this.renderLoading()}
        <div style={{ position: 'relative' }}>
          {this.renderFlashNotification()}
        </div>
      </div>
    </div>
  )
}

export default RecipesEditScreen
