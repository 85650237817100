import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Required'),
  first_name: Yup.string()
    .label('First name')
    .required('Required')
    .min(3),
  last_name: Yup.string()
    .label('Last name')
    .required('Required')
    .min(3),
  password: Yup.string()
    .when('id', (id, schema) =>
      id
        ? schema.test('six chars min', 'Password must be at least 6 characters', (value) => {
          if (value == null || value == '')
            return true
          else
            return value.length >= 6
        })
        : schema
            .required('Required')
            .min(6)
    ),
  password_confirmation: Yup.string()
    .when('id', (id, schema) =>
      id
        ? schema.when('password', (pwd, schema) => {
          return pwd == '' || pwd == null
            ? schema.oneOf([Yup.ref('password'), null], 'Passwords does not match')
            : schema
               .oneOf([Yup.ref('password'), null], 'Passwords does not match')
               .required('Required')
        })
        : schema
           .oneOf([Yup.ref('password'), null], 'Passwords does not match')
           .required('Required')
    ),
  role: Yup.string()
    .required('Required')
    .nullable()
})
