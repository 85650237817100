import React from 'react'
import map from 'lodash/map'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import Task from './Task'
import Icon from '../../shared/icon'

const Dropzone = ({
  number, stepName, title, description, currentDraggable, hoveredDraggable, updateTaskField,
  orderedTasks, removeTask, removeProductFromTask, ungroupTask, cookingModes, updateTaskProductParameter, updateTaskParameter,
  updateProductConfigurable, weightScale
}) => {
  const dropzoneFocusClass = () => {
    if (!currentDraggable) return '' // Not dragging anything

    if (stepName === 'boiler_tasks') return 'enabled' // Can drop anything into boiler tasks
    if (stepName === 'solid_topping_tasks' && currentDraggable.operation === 'add_product') return 'enabled'
    if (stepName === 'liquid_topping_tasks' && currentDraggable.operation === 'add_sauce') return 'enabled'

    return 'disabled'
  }

  const isDropDisabled = (dropzoneFocusClass() === 'disabled')

  const isDraggingProduct = currentDraggable && currentDraggable.operation === 'add_product'
  const isNotHoveringOperation = !hoveredDraggable || hoveredDraggable.operation === 'add_product'

  const isCombineEnabled = !!((stepName === 'boiler_tasks') && isDraggingProduct && isNotHoveringOperation)

  const renderWashTray = () => {
    if(stepName === 'boiler_tasks' && orderedTasks.some(task => task.operation === 'add_product')) {
      return (
        <div className='fake-task boiler-task'>
          <div className='task-items-wrapper'>
            <div className='task-item'>
              <Icon className='task-image' name='shower' color='#fff'/>
              <span className='task-name'>Wash tray</span>
            </div>
          </div>
        </div>
      )
    }
    return;
  }

  return (
    <div className={`recipe-tasks-dropzone ${dropzoneFocusClass()}`}>
      <p className='dropzone-title'>
        <span>Step {number}: </span>
        <b>{title}</b>
      </p>

      {orderedTasks.length === 0 &&
        <div className='drag-and-drop-hint'>
          {description}
        </div>
      }

      <Droppable
        droppableId={stepName}
        isDropDisabled={isDropDisabled}
        isCombineEnabled={isCombineEnabled}
      >
        {(provided, snapshot) =>
          <div
            className='droppable'
            data-step-name={stepName}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {map(orderedTasks, (task, index) =>
              <Draggable
                key={index}
                draggableId={JSON.stringify({ ...task, stepName: stepName })}
                index={index}
              >
                {(provided, snapshot) =>
                  <Task
                    {...task}
                    key={index}
                    passedRef={provided.innerRef}
                    draggableProps={provided.draggableProps}
                    dragHandleProps={provided.dragHandleProps}
                    ungroupTask={ungroupTask}
                    removeTask={() => removeTask(stepName, index)}
                    removeProductFromTask={(productIndex) => removeProductFromTask(stepName, index, productIndex)}
                    isDragging={snapshot.isDragging}
                    combineTargetFor={snapshot.combineTargetFor}
                    taskIndex={index}
                    cookingModes={cookingModes}
                    updateTaskProductParameter={updateTaskProductParameter}
                    updateTaskField={updateTaskField}
                    updateTaskParameter={updateTaskParameter}
                    stepName={stepName}
                    updateProductConfigurable={updateProductConfigurable}
                    weightScale={weightScale}
                    groupNumber={task.group_number}
                  />
                }
              </Draggable>
            )}
            {provided.placeholder}
          </div>
        }
      </Droppable>
      {renderWashTray()}
      {isDropDisabled &&
        <div className='disabled-overlay'>
          This product does not meet <br/>
          criteria for this step
        </div>
      }
    </div>
  )
}

export default Dropzone
