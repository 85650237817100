import React from 'react'
import filter from 'lodash/filter'
import map from 'lodash/map'
import { t } from '../../i18n'
import Icon from '../shared/icon'
import ReplenishmentOrderExport from './ReplenishmentOrderExport'
import { ReplenishmentOrderItem } from './ReplenishmentOrderItem'

const scope = 'replenishment.sidebar.history'

const ReplenishmentHistoryOrder = ({ replenishmentOrder, closeSidebar }) => {

  const productReplenishmentsOfType = (type: 'solid' | 'liquid') => {
    const productReplenishments = filter(replenishmentOrder.productReplenishments, replenishment =>
      replenishment.containerType === type && !replenishment.destroy
    )
    const header = type.charAt(0).toUpperCase() + type.slice(1)

    if(!productReplenishments.length) return
    return (
      <div className={`${type}-ingredients`}>
        <span className='sidebar-heading replenishment-order-subcategory'>{header} ingredients</span>
        {map(productReplenishments, (replenishment, index) =>
          <ReplenishmentOrderItem
            key={`${replenishment.product.id}-${index}`}
            type="product"
            orderItem={replenishment}
            index={index}
            showRemove={false}
          />
        )}
      </div>
    )
  }

  const plateReplenishments = () => {
    if(!replenishmentOrder.plateReplenishments.length) return
    const plateReplenishments = replenishmentOrder.plateReplenishments.filter(replenishment => !replenishment.destroy)
    return (
      <div className="plates">
        <span className='sidebar-heading replenishment-order-subcategory'>{t('sidebar.bowls', { scope })}</span>
        {map(plateReplenishments, (replenishment, index) =>
          <ReplenishmentOrderItem
            key={`${replenishment.slotNumber}-${index}`}
            type="plates"
            orderItem={replenishment}
            index={index}
            showRemove={false}
          />
        )}
      </div>
    )
  }

  return (
    <>
      <div className="sidebar-top">
        <div className='sidebar-header'>
          <span className='sidebar-heading'>{t('order_name', { scope, orderId: replenishmentOrder.id})}</span>
          <Icon name='close' className="close-icon" onClick={closeSidebar}/>
        </div>
      </div>
      <div>
        {productReplenishmentsOfType('solid')}
        {productReplenishmentsOfType('liquid')}
        {plateReplenishments()}
        <ReplenishmentOrderExport replenishmentOrder={replenishmentOrder}/>
      </div>
    </>
  )
}

export default ReplenishmentHistoryOrder
