import React, { PureComponent } from 'react'

export function setFlashMessage(flashType, flashMessage, timeOut = 3000) {
  this.setState({ flashType, flashMessage, flashOpen: true },
    () => {
      if (flashType !== 'danger') {
        setTimeout(() => this.setState({ flashOpen: false }), timeOut)
      }
    }
  )
}

class FlashNotification extends PureComponent {
  state = { open: true }

  close = () => {
    this.props.onClose && this.props.onClose()
    this.setState({ open: false })
  }

  render() {
    if (!this.state.open) return false
    return (
      <div className={`${this.props.position || 'position-fixed'}`} style={{ bottom: '20px', left: '50%', transform: 'translate(-50%, 0)', zIndex: '9999' }}>
        <div className={`row alert mb-3 alert-${this.props.type}`}>
          <div className='col p-1 d-flex justify-content-between align-items-center'>
            <i className='bi bi-exclamation-circle alert-icon mr-1' />
            <strong className='span m-l-sm mx-2'>{this.props.message}</strong>
            <i className='bi bi-x alert-icon ml-3 pointer' onClick={this.close} />
          </div>
        </div>
      </div>
    )
  }
}

export default FlashNotification
