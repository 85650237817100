import React, { useCallback, useEffect, useRef, useState } from 'react'
import axios from "axios";
import map from 'lodash/map'
import debounce from 'lodash/debounce'
import Select, { components } from 'react-select'
import { Pagination } from '@mui/material'
import filter from 'lodash/filter'
import Tooltip from '@mui/material/Tooltip'
import { extractDataErrors } from '../shared/extractDataErrors'
import FlashNotification from '../shared/flashNotification'
import SectionTitle from '../shared/sectionTitle'
import { Recipe } from './types'
import { TableColumnManager } from '../shared/TableColumnManager'
import { SortableTableHead } from '../shared/SortableTableHead'
import EmptyTablePlaceholder from '../shared/EmptyTablePlaceholder'
import { t } from '../../i18n'
import Icon from '../shared/icon'

const scope = 'recipes'
const PORTION_TYPE_BOWL = 'bowl'
const PORTION_TYPE_BATCH = 'batch'

const getRecipeColumns = (recipe) => {
  return {
    id: <td data-content='recipe-id' key={`${recipe.id}-id`}>{recipe.id}</td>,
    name: <td key={`${recipe.id}-name`}>{recipe.name}</td>,
    image_url: <td key={`${recipe.id}-image_url`} className='img recipe-image'>{recipe.image_url && <img src={recipe.image_url}/>}</td>,
    category_name: <td key={`${recipe.id}-category_name`}>{recipe.category_name}</td>,
    price:  <td key={`${recipe.price}-price`}>{recipe.price}</td>,
    created_at: <td key={`${recipe.id}-created_at`}>{recipe.created_at}</td>,
    updated_at: <td key={`${recipe.id}-updated_at`}>{recipe.updated_at}</td>,
    menu_category: <td key={`${recipe.id}-menu_category`}>{recipe.menu_category_names.join(', ') || '-'}</td>,
    type: <td key={`${recipe.id}-type`}>{renderPortionType(recipe.portion_type)}</td>
  }
}

const renderPortionType = (portionType) => (
  <Tooltip title={t(`recipes.${portionType}`)} placement='top' arrow>
    <Icon name={`${portionType}Icon`} />
  </Tooltip>
)

const DynamicRecipeRow = ({ recipe, possibleColumns }) => {
  const recipeColumns = getRecipeColumns(recipe)
  return possibleColumns.filter(c => c.selected).map((column) => {
    return recipeColumns[column.column_name]
  })
}

const RecipeActionButton = ({ action, id, icon, isDisabled = false, onClick = () => {} }) => {
  const onClickHandler = (e) => {
    e.stopPropagation() // don't click tablerow

    if (!isDisabled) {
      onClick()
    }
  }

  return (
    <Tooltip title={t(`recipes.actions.${action}`)} placement='top' arrow>
      <span
        id={id}
        className={isDisabled ? 'inactive-gray' : ''}
        onClick={onClickHandler}
      >
        <Icon name={icon} isDisabled={isDisabled} />
      </span>
    </Tooltip>
  )
}

const RecipeActionLink = ({ action, id, icon, href, isDisabled = false }) => {
  const onClickHandler = (e) => {
    e.stopPropagation() // don't click tablerow
  }

  return (
    <Tooltip title={t(`recipes.actions.${action}`)} placement='top' arrow>
      <a
        href={href}
        id={id}
        className={isDisabled ? 'inactive-gray' : ''}
        onClick={onClickHandler}
      >
        <Icon name={icon} isDisabled={isDisabled} />
      </a>
    </Tooltip>
  )
}

const Recipes: React.FC<{
  recipes: Recipe[],
  policies: Record<string, boolean>,
  sort_by: string,
  sort_order: 'asc' | 'desc'
}> = () => {
  const searchForm = useRef(null)
  const queryParams = new URLSearchParams(window.location.search)
  const [pagesCount, setPagesCount] = useState(0)
  const [possibleColumns, setPossibleColumns] = useState([])
  const [loading, setLoading] = useState(true)
  const [recipes, setRecipes] = useState([])
  const [policies, setPolicies] = useState(null)

  const [toasterMessage, setToasterMessage] = useState({
    flashType: '',
    flashOpen: false,
    flashMessage: ''
  })

  const [searchParams, setSearchParams] = useState<{
    portionType: string | null,
    currentPage: number | null,
    sortBy: string | null,
    sortOrder: string | null,
    searchInput: string | null
  }>({
    portionType: queryParams.get('type') || null,
    currentPage: parseInt(queryParams.get('page') || '') || 1,
    sortBy: queryParams.get('sort_by') || null,
    sortOrder: queryParams.get('sort_order') || null,
    searchInput: queryParams.get('q') || ''
  })

  useEffect(() => {
    getColumnNames()
  }, [])

  useEffect(() => {
    fetchRecipes()
    updateQueryParams()
  }, [searchParams])

  const portionTypeOptions = [
    { label: t('bowl_batch', { scope }), value: '' },
    { label: t(PORTION_TYPE_BOWL, { scope }), value: PORTION_TYPE_BOWL },
    { label: t(PORTION_TYPE_BATCH, { scope }), value: PORTION_TYPE_BATCH }
  ]

  const setSuccessMessage = (message) => setToasterMessage({ flashType: 'success', flashOpen: true, flashMessage: message })
  const setErrorMessages = (message) => setToasterMessage({ flashType: 'danger', flashOpen: true, flashMessage: message })
  const setInfoMessage = (message) => setToasterMessage({ flashType: 'info', flashOpen: true, flashMessage: message })
  const closeFlash = () => setToasterMessage({ flashOpen: false, flashType: '', flashMessage: '' })

  const renderFlashNotification = () => {
    if (!toasterMessage.flashOpen) return

    return (
      <FlashNotification
        type={toasterMessage.flashType}
        message={toasterMessage.flashMessage}
        onClose={closeFlash}
      />
    )
  }

  const fetchRecipes = () => {
    axios.get('/recipes.json', { params: {
      type: searchParams.portionType,
      page: searchParams.currentPage,
      sort_by: searchParams.sortBy,
      sort_order: searchParams.sortOrder,
      q: searchParams.searchInput
    }}).then(({ data }) => {
      const { recipes, policies, total_pages } = data
      setRecipes(recipes)
      setPolicies(policies)
      setPagesCount(total_pages)
      setLoading(false)
    })
  }

  const getColumnNames = () => {
    axios.get('/recipes/recipes_columns?format=json').then(res => {
      setInitialSortValues(res.data)
    })
  }

  const handleSettingChange = (record, checked) => {
    axios.put(`/dynamic_tables/${record.id}`, { record: { selected: checked } }).then(() => {
      getColumnNames()
      setSuccessMessage('Positions updated')
    }).catch(err => {
      setErrorMessages(extractDataErrors(err).full_errors)
    })
  }

  const setInitialSortValues = (dynamicColumns) => {
    const currentColumnState = dynamicColumns.map(column => {
      const selected = (column.column_name === searchParams.sortBy)

      return {
        ...column,
        sort_options: {
          selected,
          sort_order: selected ? searchParams.sortOrder : 'asc'
        }
      }
    });
    setPossibleColumns(currentColumnState)
  }

  const updateAll = (selected) => {
    axios.post('/dynamic_tables/update_all?format=json', {
      selected: selected,
      ids: possibleColumns.map(c => c.id)
    }).then(res => {
      getColumnNames()
      setSuccessMessage('Positions updated')
    }).catch(err => {
      setErrorMessages(extractDataErrors(err).full_errors)
    })
  }

  const updatePositions = (columns) => {
    setPossibleColumns(columns)
    axios.post('/dynamic_tables/update_positions?format=json', {columns}).then(res => {
      setSuccessMessage('Positions updated')
    }).catch(err => {
      setErrorMessages(extractDataErrors(err).full_errors)
    })
  }

  const selectAll = () => updateAll(true)
  const clearAll = () => updateAll(false)

  const sortableColumns = () => {
    return possibleColumns.filter(column => column.column_name !== 'image_url')
  }

  const deleteRecipe = (recipeId) => {
    if (confirm(t('recipes.delete_confirm'))) {
      axios.delete(`api/internal/recipes/${recipeId}`).then(() => {
        setRecipes(
          filter(recipes, recipe => recipe.id !== recipeId)
        )

        setInfoMessage(t('destroy_success'))
      }).catch(err => {
        setErrorMessages(err.response.data.error)
      })
    }
  }

  const handleSearch = useCallback(debounce((newSearchValue: any) => {
    setSearchParams({ ...searchParams, searchInput: newSearchValue })
  }, 300), [])

  const handleTypeFilterChange = (selected) => {
    setSearchParams({
      ...searchParams,
      portionType: selected.value,
      currentPage: 1
    })
  }

  const handlePageChange = (page) => {
    setSearchParams({ ...searchParams, currentPage: page })
    scrollToTop()
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  const updateQueryParams = () => {
    const url = new URL(window.location.href)
    const params = {
      type: searchParams.portionType,
      page: searchParams.currentPage.toString(),
      q: searchParams.searchInput
    }

    Object.keys(params).map(key => {
      if (params[key] && params[key].length > 0) {
        url.searchParams.set(key, params[key])
      } else {
        url.searchParams.delete(key)
      }
    })

    window.history.pushState(null, '', url.toString())
  }

  if (loading) return null

  return (
    <div className='row'>
      {renderFlashNotification()}
      <div className='col-sm-12'>
        <div className='mb-2 d-flex justify-content-between align-items-center'>
          <SectionTitle rows={t('all_recipes', { scope }).split(' ')}/>
          <div className='d-flex'>
            <form className='d-flex align-items-center' data-turbo-enabled={true} ref={searchForm}>
              <div className='mr-3 recipe-type-filter'>
                <Select
                  className='react-select'
                  classNamePrefix='react-select'
                  name='typeFilter'
                  defaultValue={portionTypeOptions[0]}
                  onChange={handleTypeFilterChange}
                  options={portionTypeOptions}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: (props) =>
                      <components.DropdownIndicator {...props}>
                        <Icon name='arrowDown' />
                      </components.DropdownIndicator>
                  }}
                />
              </div>
              <input type='search' autoFocus
                name='q'
                className='form-control'
                autoComplete='off'
                placeholder={t('search')}
                defaultValue={searchParams.searchInput}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </form>
            {policies.create_recipe &&
              <a href='/recipes/new' className='btn btn-primary ml-3'>+ {t('new_recipe', { scope })}</a>
            }
          </div>
        </div>

        {recipes.length === 0 &&
          <EmptyTablePlaceholder heading={t('recipes.no_records_heading')}/>
        }

        {recipes.length > 0 &&
          <>
            <table className='table table-robis recipe-table'>
              <SortableTableHead
                possibleColumns={possibleColumns}
                sortableColumns={sortableColumns()}
                modelIndexBasePath="/recipes"
              >
                <TableColumnManager
                  possibleColumns={possibleColumns}
                  emptyColumns={1}
                  handleSettingChange={handleSettingChange}
                  selectAll={selectAll}
                  clearAll={clearAll}
                  updatePositions={updatePositions}
                />
              </SortableTableHead>
              <tbody>
              {map(recipes, recipe =>
                <tr
                  key={recipe.id}
                  onClick={() => { window.location.href = `/recipes/${recipe.id}/edit` }}
                  className={recipe.is_draft ? 'inactive-gray' : ''}
                >
                  <DynamicRecipeRow recipe={recipe} possibleColumns={possibleColumns} />
                  <td>
                    {recipe.policies.update &&
                      <div className='d-flex align-items-center'>
                        <RecipeActionButton
                          action='delete'
                          id={`delete-recipe-${recipe.id}`}
                          icon='deleteSmall'
                          onClick={() => deleteRecipe(recipe.id)}
                          isDisabled={recipe.is_draft}
                        />
                        <RecipeActionButton
                          action='archive'
                          id={`archive-recipe-${recipe.id}`}
                          icon='archive'
                          isDisabled={true} // archiving not implemented
                        />
                        <RecipeActionButton
                          action='create_new_version'
                          id={`create-new-version-recipe-${recipe.id}`}
                          icon='copy'
                          isDisabled={true} // version copy not implemented
                        />
                        <RecipeActionLink
                          href={`/recipes/${recipe.id}/edit`}
                          action='edit'
                          id={`edit-recipe-${recipe.id}`}
                          icon='editSmall'
                        />
                      </div>
                    }
                  </td>
                </tr>
              )}
              </tbody>
            </table>

            <Pagination
              count={pagesCount}
              size='large'
              page={searchParams.currentPage}
              variant='outlined'
              shape='rounded'
              onChange={(e, page) => handlePageChange(page)}
            />
          </>
        }
      </div>
    </div>
  )
}

export default Recipes
