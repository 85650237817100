import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import ProductCategories from './ProductCategories'
import ProductCategory from './ProductCategory'
import ModalForm from './ModalForm'
import axios from 'axios'
import { DeleteModal } from '../shared/deleteModal'
import Autocomplete from '../shared/autocomplete'
import { DropContainer, reorder } from '../shared/draggable'
import { Draggable } from 'react-beautiful-dnd'
import Icon from '../shared/icon'
import Loading from '../shared/loading'
import FlashNotification from '../shared/flashNotification'
import { extractDataErrors } from '../shared/extractDataErrors'
import { WeightScaleContextProvider } from '../shared/contexts'

class ProductCategoriesIndex extends Component {
  state = {
    search: '',
    edit: false,
    modalOpen: false,
    categories: [],
    locations: [],
    refresh: false,
    selectedObject: {},
    deletableObject: null,
    deleteSuccessfull: false,
    deleteFailMessage: null,
    loading: false,
    show: false,
    categoryChildren: [],
    returnToShowAfter: false,
    selectedCategory: null
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    this.startLoading()
    axios.all([
      axios.get(Routes.roots_product_categories_path({ format: 'json' })),
      axios.get('/product_categories/select_options?format=json')
    ]).then(response => {
      this.setState({ categories: response[0].data, locations: response[1].data.product_categories, refresh: true })
      this.stopLoading()
    }).catch(err => {
      this.stopLoading()
      this.setErrorMessages(extractDataErrors(err).full_errors)
    })
  }

  startLoading = () => this.setState({ loading: true })
  stopLoading = () => this.setState({ loading: false })
  turnOnEdit = (e) => this.setState({ edit: true })
  turnOffEdit = (e) => this.setState({ edit: false })
  openModal = (e) => this.setState({ modalOpen: true, show: false })
  closeModal = (e) => this.setState({ modalOpen: false, selectedObject: {} }, this.fetchData)
  setRefreshToFalse = () => this.setState({ refresh: false })
  openChilds = (category) => {
    const slicedCategories = this.state.categories.slice(0)
    const index = slicedCategories.findIndex(c => c.id === category.id || this.isChildInCategory(category.id, c))
    if (index >= 0) {
      slicedCategories[index].childsOpen = !slicedCategories[index].childsOpen
      this.setState({ categories: slicedCategories })
    }
  }

  isChildInCategory = (childId, selectedCategory) => {
    return selectedCategory.children.some(child => child.class_name === 'ProductCategory' && child.id === childId)
  }

  setSelectedCategory(category) {
    this.setState({ selectedCategory: category }, () => this.openChilds(category))
  }

  setObjectForEdit(object) {
    this.setState({ selectedObject: object, modalOpen: true, show: false })
  }

  setObjectForShow(object) {
    this.setState({ selectedObject: object, modalOpen: true, show: true, returnToShowAfter: false })
  }

  showToEdit = () => this.setState({ show: false, returnToShowAfter: true })

  openDeleteModal = (object) => this.setState({ deletableObject: object, modalOpen: false, selectedObject: {} })
  closeDeleteModal = () => this.setState({ deletableObject: null, deleteSuccessfull: false }, this.fetchData)

  handleDelete(object) {
    const url = object.class_name === 'Product' ? Routes.product_path(object.id) : Routes.product_category_path(object.id)
    axios.delete(url).then(res => {
      this.setState({ deleteSuccessfull: true })
    }).catch(err => {
      this.setState({ deleteFailMessage: err.response.data.base && err.response.data.base.join(', ') })
    })
  }

  fetchCategoryChildren(categoryId) {
    const openChilds = this.state.categoryChildren.filter(c => c.tableChildsOpen).map(c => c.id)
    axios.get(Routes.product_category_path(categoryId, { format: 'json' })).then(res => {
      const categories = res.data.map((c, i) => {
        if (openChilds.includes(c.id)) {
          c.tableChildsOpen = true
        }
        c.position = i + 1
        return c
      })
      this.setState({ categoryChildren: categories })
    }).catch(err => {
      this.setErrorMessages(extractDataErrors(err).full_errors)
    })
  }

  toggleTableChilds(category) {
    const splicedCategories = this.state.categoryChildren.slice(0)
    const index = splicedCategories.findIndex(c => c.id === category.id && category.class_name === c.class_name)
    splicedCategories[index].tableChildsOpen = !splicedCategories[index].tableChildsOpen
    this.setState({ categoryChildren: splicedCategories })
  }

  updatePositions = (categories) => {
    this.setState({ categories })
    axios.post(Routes.update_positions_product_categories_path({ format: 'json' }), { categories }).then(res => {
      this.setSuccessMessage('Positions updated')
    }).catch(err => {
      this.setErrorMessages(extractDataErrors(err).full_errors)
    })
  }

  updateTablePositions = (result) => {
    let items
    if (result.type === 'NESTED') {
      items = this.state.categoryChildren.slice(0)
      const openId = result.destination.droppableId.split('-')[1]
      const openChild = this.state.categoryChildren.filter(c => c.id == openId && c.class_name === 'ProductCategory')[0]
      const children = reorder(
        openChild.children,
        result.source.index - openChild.position,
        result.destination.index - openChild.position
      )
      const index = items.findIndex(c => c.id == openId && c.class_name === 'ProductCategory')
      items[index].children = children
    } else {
      items = reorder(
        this.state.categoryChildren,
        result.source.index,
        result.destination.index
      )
    }
    this.setState({ categoryChildren: items })
    axios.post(Routes.update_table_positions_product_categories_path({ format: 'json' }), { categoryChildren: items }).then(res => {
      this.setSuccessMessage('Positions updated')
    }).catch(err => {
      this.setErrorMessages(extractDataErrors(err).full_errors)
    })
  }

  renderEdit() {
    if (this.state.edit) {
      return (
        <div className='category-buttons'>
          <button type="button" className='btn btn-secondary ml-3 done-button' onClick={this.turnOffEdit}>
            <Icon name='doneIcon'/> <span className="secondary-edit-icon-text">Done</span>
          </button>
        </div>
      )
    } else {
      return (
        <div className='category-buttons'>
            <button type="button" className='btn btn-secondary ml-3 d-flex align-items-center' onClick={this.turnOnEdit}>
              <div className="center">
                <Icon name='editIcon' className='secondary-edit-icon mr-1' />
                <span className="secondary-edit-icon-text">Edit</span>
              </div>
            </button>
            <button type="button" className='btn btn-primary ml-3' onClick={this.openModal}>
              + Add New
            </button>
         </div>
      )
    }
  }

  renderCategoryChilds(category) {
    if (category.childsOpen) {
      return category.children.map(child => {
        return (
          <div className={`robis-nested pointer ${this.state.selectedCategory?.id === child.id ? 'selected-item' : ''}`} key={child.id} onClick={(e) => this.navigateToCategory(child, e)}>
            {child.name}
          </div>
        )
      })
    }
  }

  navigateToCategory(category, e) {
    e.preventDefault()
    e.stopPropagation()

    if(!this.state.selectedCategory?.id) {
      window.location = `/products/${category.id}`
      return
    }

    window.history.replaceState({category: category.id}, "", `/products/${category.id}`)
    this.setState({ refresh: true, selectedCategory: category })
  }

  categoryHasChildren(category) {
    return category.children.length > 0
  }

  renderProductCategoryNavbar() {
    const { edit, categories, selectedCategory } = this.state

    return (
      <DropContainer updatePositions={this.updatePositions.bind(this)} collection={categories}>
        <div className="robis-sidebar">
          {this.state.categories.map((category, index) => {
            const arrowDirection = category.childsOpen ? 'arrowUp' : 'arrowDown'
            return (
              <React.Fragment key={category.id}>
                <Draggable key={category.id} draggableId={`drag-${category.id}`} index={index} isDragDisabled={!edit}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`d-flex justify-content-between align-items-center sidebar-item pointer ${snapshot.isDragging ? 'dragging-row' : ''} ${selectedCategory?.id === category.id ? 'selected-item' : ''}`}
                      onClick={this.navigateToCategory.bind(this, category)}
                    >
                      <div className="sidebar-content">
                        {edit && <Icon name='dragBurger' className='mr-2 drag-icon' />}
                        <b className='as-link'>{category.name}</b>
                      </div>
                      {this.categoryHasChildren(category) && <Icon name={arrowDirection} onClick={() => this.openChilds(category)}/>}
                    </div>
                  )}
                </Draggable>
                {this.renderCategoryChilds(category)}
              </React.Fragment>
            )
          })}
        </div>
      </DropContainer>
    )
  }

  renderBreadcrumbs() {
    const { selectedCategory } = this.state
    if (selectedCategory) {
      return (
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href='/products'>All categories</a></li>
          {selectedCategory.ancestry.map(category => {
            const activeClassName = category.name === selectedCategory.name ? 'active' : ''
            return (
              <li key={category.id} className="breadcrumb-item" aria-current="page">
                <a href={`/products/${category.id}`} className={`${activeClassName}`}>{category.name}</a>
              </li>
            )
          })}
        </ol>
      )
    } else {
      return <h3 className="font-weight-bold margin-left-20">All categories</h3>
    }
  }

  renderLoading = () => this.state.loading && <Loading />
  setSuccessMessage = (message) => this.setState({ flashType: 'success', flashOpen: true, flashMessage: message })
  setErrorMessages = (message) => this.setState({ flashType: 'danger', flashOpen: true, flashMessage: message })
  closeFlash = () => this.setState({ flashOpen: false })
  renderFlashNotification = () => {
    if (this.state.flashOpen) {
      return <FlashNotification type={this.state.flashType} message={this.state.flashMessage} onClose={this.closeFlash} />
    }
  }

  render() {
    return (
      <div>
        {this.renderFlashNotification()}
        {this.renderLoading()}
        <div className='header mb-3 d-flex justify-content-between align-items-center'>
          {this.renderBreadcrumbs()}
          <div className='d-flex align-items-center'>
            <div className='d-flex align-items-center form-control'>
              <i className="bi bi-search"></i>
              <Autocomplete setObjectForShow={this.setObjectForShow.bind(this)} selectedCategory={this.state.selectedCategory}/>
            </div>
            {this.renderEdit()}
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-2 p-0">
              {this.renderProductCategoryNavbar()}
            </div>
            <div className="col-10 p-0 product-cards">
              <Router>
                <Switch>
                  <Route path='/products/:category' render={(componentProps) =>
                    <ProductCategory
                      {...componentProps}
                      setRefreshToFalse={this.setRefreshToFalse.bind(this)}
                      selectedCategory={this.state.selectedCategory}
                      setSelectedCategory={this.setSelectedCategory.bind(this)}
                      refresh={this.state.refresh}
                      categories={this.state.categories}
                      edit={this.state.edit}
                      setObjectForEdit={this.setObjectForEdit.bind(this)}
                      setObjectForShow={this.setObjectForShow.bind(this)}
                      destroy={this.openDeleteModal}
                      categoryChildren={this.state.categoryChildren}
                      fetchCategoryChildren={this.fetchCategoryChildren.bind(this)}
                      toggleTableChilds={this.toggleTableChilds.bind(this)}
                      setSuccessMessage={this.setSuccessMessage}
                      setErrorMessages={this.setErrorMessages}
                      updateTablePositions={this.updateTablePositions}
                    />}
                  />
                  <Route path='/products' render={(componentProps) =>
                    <ProductCategories
                      updatePositions={this.updatePositions.bind(this)}
                      {...componentProps}
                      setObjectForEdit={this.setObjectForEdit.bind(this)}
                      categories={this.state.categories}
                      edit={this.state.edit} />}
                  />
                </Switch>
              </Router>
            </div>
          </div>
        </div>
        <WeightScaleContextProvider value={this.props.weight_scale}>
          {this.state.deletableObject &&
            <DeleteModal
              object={this.state.deletableObject}
              closeModal={this.closeDeleteModal}
              deleteSuccessful={this.state.deleteSuccessfull}
              deleteFailMessage={this.state.deleteFailMessage}
              handleDelete={this.handleDelete.bind(this)} />}

          <ModalForm
            modalOpen={this.state.modalOpen}
            closeModal={this.closeModal}
            locations={this.state.locations}
            refetchCategories={this.fetchData}
            selectedObject={this.state.selectedObject}
            destroy={this.openDeleteModal}
            show={this.state.show}
            openEdit={this.showToEdit}
            returnToShowAfter={this.state.returnToShowAfter}
            setObjectForShow={this.setObjectForShow.bind(this)}
            selectedCategory={this.state.selectedCategory}
          />
          </WeightScaleContextProvider>
      </div>
    )
  }
}

export default ProductCategoriesIndex
