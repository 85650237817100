import React, { Component, Fragment } from 'react'
import Select from 'react-select'
import axios from 'axios'
import map from 'lodash/map'
import { debounce } from 'lodash'
import { Pagination } from '@mui/material'
import OrderModal from './orderModal'
import EmptyTablePlaceholder from '../shared/EmptyTablePlaceholder'
import Tabs from '../shared/Tabs'
import { t } from '../../i18n'

const scope = 'orders'

const tabs = [
  { name: 'order_queue', label: t('queue', { scope }) },
  { name: 'order_history', label: t('history', { scope }) },
]

const TableCell = ({ value, className }) => {
  const style = value ? {} : { opacity: '0.4' }

  return (
    <td className={className} style={style}>{value || 'N / A'}</td>
  )
}

const StateCell = ({ orderId, value, canConfirm, canCancel }) => {
  let dropdownContent = []

  if (canConfirm) {
    dropdownContent.push(
      <a
        href={`/orders/${orderId}/confirm_manual_payment`}
        className='dropdown-item'
        data-method='post'
        key='confirm_manual_payment'
      >
        Mark as paid
      </a>
    )
  }

  if (canCancel) {
    dropdownContent.push(
      <a
        href={`/orders/${orderId}/cancel_manual_payment`}
        className='dropdown-item danger'
        data-method='post'
        key='cancel_manual_payment'
      >
        Cancel
      </a>
    )
  }

  if (dropdownContent.length === 0 || value !== 'payment_processing') {
    return <td className={`state-${value}`}>{value}</td>
  }

  const dropdownId = `state-dropdown-order-${orderId}`

  return <td className={`${value}`}>
    <div className='dropdown'>
      <span className='state-dropdown-toggle' id={dropdownId} data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
        unpaid
      </span>
      <div className='dropdown-menu' aria-labelledby={dropdownId}>
        {dropdownContent}
      </div>
    </div>
  </td>
}

const QueueTable = ({ queuedOrders }) => {
  if (!queuedOrders.length) {
    return (
      <EmptyTablePlaceholder
        heading={t('order.no_active_records_heading')}
        subtext={t('order.no_active_records_subtext')}
      />
    )
  }
  return (
    <table className='table table-robis table-order-queue'>
      <thead className='thead-robis'>
        <tr>
          <th>#</th>
          <th></th>
          <th>Recipe</th>
          <th>Order</th>
          <th>Queue</th>
          <th>State</th>
          <th>Planned time</th>
          <th>Planned end time</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {map(queuedOrders, queuedOrder =>
          <QueuedOrderRow
            key={queuedOrder.id}
            queuedOrder={queuedOrder}
          />
        )}
      </tbody>
    </table>
  )
}

const openLockerButton = (queuedOrder) => {
  if (!queuedOrder.locker_openable) return

  return (
    <a
      className='btn btn-secondary'
      href={`queued_orders/${queuedOrder.id}/open_locker`}
      data-method='put'
      data-confirm={t('global.confirm')}
    >
      {`Open locker #${queuedOrder.locker_number}`}
    </a>
  )
}


const cancelQueuedOrderButton = (queuedOrder) => {
  if(queuedOrder.state !== 'pending' && queuedOrder.state !== 'queued') return

  return (
    <a
      className='btn btn-secondary'
      href={`queued_orders/${queuedOrder.id}/cancel`}
      data-method='put'
      data-confirm={t('global.confirm')}
    > {t('cancel')}
    </a>
  )
}

const QueuedOrderRow = ({ queuedOrder }) => {
  return (
    <tr>
      <TableCell value={queuedOrder.id} />
      <td className='img'>{queuedOrder.recipe_image_url && <img src={queuedOrder.recipe_image_url}/>}</td>
      <TableCell value={queuedOrder.recipe_name} />
      <TableCell value={queuedOrder.order_id ? `#${queuedOrder.order_id}` : null} />
      <TableCell value={`#${queuedOrder.queue_number}`} />
      <TableCell className={`state-${queuedOrder.state}`} value={queuedOrder.state.replace("_", " ")}/>
      <TableCell value={queuedOrder.planned_time} />
      <TableCell value={queuedOrder.planned_end_time} />
      <td>{cancelQueuedOrderButton(queuedOrder)}</td>
      <td>{openLockerButton(queuedOrder)}</td>
    </tr>
  )
}

const HistoryTable = ({
  historyOrders, orderQueueOpen, currency, currentPage, totalPages, handlePageChange
}) => {
  if (historyOrders.length === 0) {
    return <EmptyTablePlaceholder heading={t('order.no_history_records_heading')}/>
  }

  return (
    <>
      <table className='table table-robis table-order-history'>
        <thead className='thead-robis'>
          <tr>
            <th>#</th>
            <th>Number</th>
            <th className='text-center'>Items</th>
            <th>Date</th>
            <th>Sum ({currency.code})</th>
            <th>Payment type</th>
            <th>Status</th>
            <th>Estimate</th>
            <th>Finished</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {map(historyOrders, historyOrder =>
            <HistoryOrderRow
              key={historyOrder.id}
              historyOrder={historyOrder}
              orderQueueOpen={orderQueueOpen}
              currency={currency}
            />
          )}
        </tbody>
      </table>
      <Pagination
        count={totalPages}
        size="large"
        page={parseInt(currentPage)}
        variant="outlined"
        shape="rounded"
        onChange={handlePageChange}
      />
    </>
  )
}

const HistoryOrderRow = ({ historyOrder, orderQueueOpen, currency }) => {
  const { policies } = historyOrder

  return (
    <tr>
      <TableCell value={historyOrder.queued_order_code ? `#${historyOrder.queued_order_code}` : null} />
      <TableCell value={historyOrder.number} />
      <td className='items'>
        <div className='dropdown'>
          <div
            className='pointer'
            id={`order-items-${historyOrder.id}`}
            data-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            <div className='items-amount'>{historyOrder.order_items_amount}</div>
            {historyOrder.canceled_order_items_amount > 0 &&
              <div className='canceled-items-amount'>
                -{historyOrder.canceled_order_items_amount}
              </div>
            }
          </div>
          <div className='dropdown-menu' aria-labelledby={`order-items-${historyOrder.id}`}>
            {map(historyOrder.order_items, (oi, index) =>
              <div className='dropdown-item d-flex align-items-center' key={index}>
                <div className='mr-2'>
                  <img style={{ maxHeight: '36px' }} src={oi.recipe_image_url} />
                </div>
                <div className='mr-5 flex-1'>{oi.recipe_name}</div>
                <div className='mr-4'>{`${oi.recipe_price} ${currency.code}`}</div>
                <div>{`${oi.amount}x`}</div>
              </div>
            )}
          </div>
        </div>
      </td>
      <TableCell value={historyOrder.date} />
      <TableCell value={historyOrder.total_price} />
      <TableCell value={historyOrder.payment_type} />
      <StateCell
        orderId={historyOrder.id}
        value={historyOrder.state}
        canConfirm={policies.confirm_manual_payment && orderQueueOpen}
        canCancel={policies.cancel_manual_payment}
      />
      <TableCell value={historyOrder.queued_order_preparation_end_time} />
      <TableCell value={historyOrder.finished_at} />
      <td>
        {policies.mark_as_delivered && historyOrder.ready_for_delivery &&
          <div>
            <a href={`/orders/${historyOrder.id}/mark_as_delivered`} data-method='post'>
              Mark as delivered
            </a>
          </div>
        }
        {policies.cancel_manually_paid_payment &&
          <div>
            <a href={`/orders/${historyOrder.id}/cancel_manually_paid_payment`} data-method='post'>
              Cancel paid order
            </a>
          </div>
        }
      </td>
    </tr>
  )
}

class Orders extends Component {
  constructor(props) {
    super(props)

    this.searchForm = React.createRef()
    const searchParams = new URLSearchParams(window.location.search)

    this.state = {
      queuedOrders: props.queued_orders,
      historyOrders: [],
      robInResetState: props.rob_in_reset_state,
      orderSchedulingEnabled: props.order_scheduling_enabled,
      shouldResetQueuedOrders: props.should_reset_queued_orders,
      shouldCancelQueuedOrders: props.should_cancel_queued_orders,
      searchInput: searchParams.get('q') || '',
      currentPage: searchParams.get('page') || '1',
      status: searchParams.get('status') || '',
      currentTabIndex: parseInt(searchParams.get('tab')) || 0,
      pagesCount: 0,
    }
  }

  componentDidMount() {
    this.fetchHistoryOrders(false)
  }

  handleHistoryFiltersChange = (resetPage = true) => {
    this.fetchHistoryOrders(resetPage)
  }

  fetchHistoryOrders = (resetPage) => {
    const params = new URLSearchParams([
      ['page', resetPage ? '1' : this.state.currentPage],
      ['q', this.state.searchInput],
      ['status', this.state.status],
      ['format', 'json']])

    axios.get('/orders/history_orders', { params }).then(({data}) => {
      this.setState({ historyOrders: data.history_orders, pagesCount: data.page_count })
    })
  }

  resetQueue = (type) => {
    if (!confirm('Are you sure?')) return

    axios.post('/orders/reset_queue', { type }).then(({ data }) => {
      const stateKey = (type === 'cancel') ? 'shouldCancelQueuedOrders' : 'shouldResetQueuedOrders'

      this.setState({ [stateKey]: true }, () => alert(data))
    }).catch(({ response: { data } }) => alert(data))
  }

  handleSearch = debounce((newSearchValue) => {
    const hasMinLength = newSearchValue.length > 3
    const isRemovingLetters = this.removingLettersFromSearch(this.state.searchInput, newSearchValue)
    const hasSearchForm = this.searchForm != null

    if((hasMinLength || isRemovingLetters) && hasSearchForm) {
      this.searchForm.current.requestSubmit()
    } else {
      this.setState({ searchInput: newSearchValue })
      this.handleHistoryFiltersChange()
    }
  }, 300)

  removingLettersFromSearch = (previousValue, newValue) => {
    return previousValue.length > newValue.length
  }

  changeStatus = (e) => {
    this.setState({ status: e.value }, this.handleHistoryFiltersChange)
  }

  changePage = (page) => {
    this.setState({ currentPage: page }, () => this.handleHistoryFiltersChange(false))
  }

  switchTab = (index) => {
    const url = new URL(window.location.href)
    url.searchParams.set('tab', index)
    window.history.pushState(null, '', url.toString())

    this.setState({ currentTabIndex: index })
  }

  getTabNameByIndex = (tabIndex) => {
    return tabs[tabIndex].name
  }

  renderSearchForm = () => {
    const order_statuses = this.props.order_statuses
    const { currentTabIndex, status, searchInput } = this.state
    const urlSearchParams = new URLSearchParams(window.location.search)

    const orderStatusesWithBlank = [
      { label: t('status.all_today', { scope }), value: '' },
      { label: t('status.all', { scope }), value: 'all_all' },
      ...order_statuses
    ]

    return (
      <form className='d-flex align-items-center search-form' data-turbo-enabled={true} ref={this.searchForm}>
        <input type='hidden' name='tab' value={currentTabIndex} />
        <div className='flex-1'>
          <Select
            className='react-select mr-2'
            classNamePrefix='react-select'
            name='status'
            placeholder='Status'
            defaultValue={orderStatusesWithBlank.find(s => s.value === (status || urlSearchParams.get('status') || ''))}
            onChange={this.changeStatus}
            options={orderStatusesWithBlank}
          />
        </div>
        <div className='flex-1'>
          <input
            type='search'
            name='q'
            className='form-control'
            autoComplete='off'
            autoFocus
            placeholder='Search...'
            defaultValue={searchInput}
            onChange={(e) => this.handleSearch(e.target.value)}
            />
        </div>
      </form>
    )
  }

  render() {
    const { order_queue_open, policies, active_recipes } = this.props
    const {
      queuedOrders, historyOrders, robInResetState, shouldResetQueuedOrders,
      shouldCancelQueuedOrders, orderSchedulingEnabled, currentTabIndex
    } = this.state

    const currentTabName = this.getTabNameByIndex(currentTabIndex)

    return (
      <div className='row'>
        <div className='orders-container col-sm-12'>
          <div className='orders-header'>
            <div className='section-heading pl-3 pt-2'>
              <div className='section-heading-row bold'>
                {tabs[currentTabIndex].label}
              </div>
            </div>
            <Tabs
              tabs={tabs}
              currentTabIndex={currentTabIndex}
              switchTab={this.switchTab}
              className='tabs-wrapper'
            />
            <div className='mb-1 d-flex justify-content-between align-items-center'>
              {currentTabName === 'order_queue' &&
                <>
                  {!order_queue_open &&
                    <span className='text-danger mr-3'>Closed for orders</span>
                  }
                  {!orderSchedulingEnabled &&
                    <span className='text-danger mr-3'>Order scheduling disabled</span>
                  }
                  {robInResetState && !shouldResetQueuedOrders && !shouldCancelQueuedOrders &&
                    <Fragment>
                      <span className='mr-3 text-danger font-weight-bold'>Robot has been restarted</span>
                      <button className='btn btn-danger mr-3' onClick={() => this.resetQueue('cancel')}>
                        Cancel Queue
                      </button>
                      <button className='btn btn-danger mr-3' onClick={() => this.resetQueue('reset')}>
                        Restart Queue
                      </button>
                    </Fragment>
                  }
                  {policies.create_order &&
                    <OrderModal
                      activeRecipes={active_recipes}
                      newOrderAvailable={order_queue_open}
                      currency={this.props.currency}
                    />
                  }
                </>
              }
              {currentTabName === 'order_history' && this.renderSearchForm()}
            </div>
          </div>

          <div className='orders-body'>
            <div className='tab-content-wrapper'>
              {currentTabName === 'order_queue' &&
                <QueueTable queuedOrders={queuedOrders} />
              }
              {currentTabName === 'order_history' &&
                <HistoryTable
                  historyOrders={historyOrders}
                  orderQueueOpen={order_queue_open}
                  currency={this.props.currency}
                  currentPage={this.state.currentPage}
                  totalPages={this.state.pagesCount}
                  handlePageChange={(e, page) => this.changePage(page)}
                />
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Orders
