import React, { Component } from 'react'
import { DebounceInput } from 'react-debounce-input'
import includes from 'lodash/includes'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import axios from 'axios'
import ProductCategories from './ProductCategories'
import Dropzone from './Dropzone'
import DraggableProduct from './DraggableProduct'
import DraggableOperation from './DraggableOperation'
import FlashNotification, { setFlashMessage } from '../../shared/flashNotification'
import { CookingMode, RecipeTask, ProductCategoryTree } from '../types'
import { t } from '../../../i18n'

const scope = 'recipes'

const STEPS = [
  {
    number: 1,
    name: 'boiler_tasks',
    title: t('recipe_tasks.steps.cooking_module', { scope }),
    description: t('recipe_tasks.steps.boiler_tasks', { scope })
  },
  {
    number: 2,
    name: 'solid_topping_tasks',
    title: t('recipe_tasks.steps.add_solid_toppings', { scope }),
    description: t('recipe_tasks.steps.solid_topping_tasks', { scope })
  },
  {
    number: 3,
    name: 'liquid_topping_tasks',
    title: t('recipe_tasks.steps.add_liquid_toppings', { scope }),
    description: t('recipe_tasks.steps.liquid_topping_tasks', { scope })
  },
]

const OPERATIONS = [
  { operation: 'set_temperature', name: 'Set temperature' },
  { operation: 'set_cooking_mode', name: 'Cooking mode' },
  { operation: 'wash_tray', name: 'Wash tray' },
]

const ProductDescription = ({ expandedProduct, onClose }) => {
  return (
    <div className='expanded-product'>
      <span className='expanded-product-close-button' onClick={onClose}>&times;</span>
      <div className='product-header'>
        <div className='product-image'>
          <img src={expandedProduct.file_url} />
        </div>
        <div className='product-main-info'>
          <h5 className='product-name'>{expandedProduct.name}</h5>
          <p className='product-description'>{expandedProduct.notes || '-'}</p>
        </div>
      </div>
      <hr/>
      <div className='product-body'>
        {/* TODO: Show product data when ingredient library is ready */}
      </div>
    </div>
  )
}

const SearchProductField = ({ searchProductQuery, searchProduct, clearProductsSearch, expandedProduct }) => {
  return (
    <div className='form-group'>
      <div className='search-input-wrapper'>
        <DebounceInput
          className={`form-control inverted ${expandedProduct ? 'disabled' : ''}`}
          placeholder='search ...'
          value={!expandedProduct ? searchProductQuery : ''}
          debounceTimeout={500}
          onChange={searchProduct}
          disabled={expandedProduct}
        />
        {searchProductQuery && searchProductQuery.length > 0 && !expandedProduct &&
          <span className='cancel-search-button' onClick={clearProductsSearch}>&times;</span>
        }
      </div>
    </div>
  )
}

const SearchProductResults = ({ searchProductResults, expandProduct }) => {
  return (
    <div className='search-products-result'>
      <Droppable droppableId='add_product' isDropDisabled={true}>
        {(provided, snapshot) =>
          <div className='products-list' ref={provided.innerRef}>
            {searchProductResults.length === 0 &&
              <p className='text-secondary my-3 mx-4'>No products found</p>
            }
            {map(searchProductResults, (product, index) =>
              <DraggableProduct {...product}
                expandProduct={expandProduct}
                key={product.id}
                index={index}
              />
            )}
          </div>
        }
      </Droppable>
    </div>
  )
}

const OperationsList = () => {
  return (
    <div>
      <span className='recipe-tasks-heading'>{t('recipe_tasks.heading', { scope })}</span>
      <span className='recipe-tasks-subtext'>{t('recipe_tasks.subtext', { scope })}</span>
      <div className='operations-list'>
        <Droppable droppableId='operations-list' isDropDisabled={true} key='operations-list'>
          {(provided, _snapshot) =>
            <div ref={provided.innerRef}>
              {map(OPERATIONS, (operation, index) => (
                <DraggableOperation
                  {...operation}
                  index={index}
                  key={operation.name}
                />
              ))}
              {provided.placeholder}
            </div>
          }
        </Droppable>
      </div>
    </div>
  )
}

interface Props {
  tasks: {
    boiler_tasks: RecipeTask[],
    solid_topping_tasks: RecipeTask[],
    liquid_topping_tasks: RecipeTask[],
  },
  cookingModes: CookingMode[],
  categorizedProducts: ProductCategoryTree[],
  updateTasks: Function,
  weightScale: string
}

interface State {
  currentDraggable: object,
  hoveredDraggable: Object,
  expandedProduct: Object,
  searchProductQuery: string,
  searchProductResults: Object[],
  expandedCategoryColIndex: number | null,
  expandedCategoryId: number | null,
  expandedSubcategoryId: number | null,
  flashOpen: boolean,
  flashType: string | null,
  flashMessage: string | null,
}

class RecipeTasks extends Component<Props, State> {
  setFlashMessage: Function

  constructor(props) {
    super(props)

    this.state = {
      currentDraggable: null,
      hoveredDraggable: null,
      expandedProduct: null,
      searchProductQuery: '',
      searchProductResults: [],
      expandedCategoryColIndex: null,
      expandedCategoryId: null,
      expandedSubcategoryId: null,
      flashOpen: false,
      flashType: null,
      flashMessage: null,
    }

    this.setFlashMessage = setFlashMessage.bind(this)
  }

  renderFlashNotification = () => this.state.flashOpen && (
    <FlashNotification
      type={this.state.flashType}
      message={this.state.flashMessage}
      onClose={() => this.setState({ flashOpen: false })}
    />
  )

  onDragStart = (e) => {
    const currentDraggable = JSON.parse(e.draggableId)

    this.setState({ currentDraggable })
  }

  onDragUpdate = (e) => {
    const hoveredDraggable = e.combine && JSON.parse(e.combine.draggableId)

    this.setState({ hoveredDraggable })
  }

  onDragEnd = (e) => {
    this.setState({ currentDraggable: null, hoveredDraggable: null })

    const taskStepNames = map(STEPS, ({ name }) => name)

    if (e.combine) {
      this.combineTasks(e)
    } else if (includes(taskStepNames, e.source.droppableId)) {
      this.moveTask(e.source, e.destination)
    } else if (e.destination) {
      const draggableTask = JSON.parse(e.draggableId)

      this.insertTask(draggableTask, e.destination)
    }
  }

  combineTasks = (e) => {
    const draggableTask = JSON.parse(e.draggableId)

    const srcStepName = e.source.droppableId
    const srcIndex = e.source.index
    const destStepName = e.combine.droppableId
    const destIndex = JSON.parse(e.combine.draggableId).task_number - 1

    const draggingExistingTask = includes(
      ['boiler_tasks', 'solid_topping_tasks', 'liquid_topping_tasks'],
      srcStepName
    )

    if (draggingExistingTask) {
      const srcTasks = [...this.props.tasks[srcStepName]]

      const destTasks = (srcStepName === destStepName)
        ? srcTasks // Combining already existing tasks
        : [...this.props.tasks[destStepName]]

      const destTask = destTasks[destIndex]

      if (draggableTask.operation !== 'add_product' || destTask.operation !== 'add_product') {
        return // Can combine products only (not sauces)
      }

      destTask.recipe_task_products.push(...draggableTask.recipe_task_products)
      srcTasks.splice(srcIndex, 1)

      this.props.updateTasks({ [srcStepName]: srcTasks, [destStepName]: destTasks })
    } else if (draggableTask.operation === 'add_product') {
      // Dragging in a new product

      const destTasks = [...this.props.tasks[destStepName]]
      const destTask = destTasks[destIndex]
      destTask.recipe_task_products.push({ ...draggableTask, amount: 0 })

      this.props.updateTasks({ [destStepName]: destTasks })
    }
  }

  moveTask = (source, destination) => {
    const srcStepName = source.droppableId
    const destStepName = destination.droppableId

    let srcTasks = [...this.props.tasks[srcStepName]]
    let destTasks = (srcStepName === destStepName)
      ? srcTasks // srcTasks and destTasks are the same list
      : [...this.props.tasks[destStepName]]

    let movableTasks = srcTasks.splice(source.index, 1)

    // Split combined tasks when moving to toppings
    if (destStepName === 'solid_topping_tasks' && movableTasks[0].recipe_task_products.length > 1) {
      movableTasks = map(movableTasks[0].recipe_task_products, product => {
        return {
          operation: movableTasks[0].operation,
          parameters: movableTasks[0].parameters,
          recipe_task_products: [product],
        }
      })
    }

    destTasks.splice(destination.index, 0, ...movableTasks)

    this.props.updateTasks({ [srcStepName]: srcTasks, [destStepName]: destTasks })
  }

  insertTask = (draggableTask, destination) => {
    const destStepName = destination.droppableId
    const destIndex = destination.index

    let recipeTask: RecipeTask = {
      operation: draggableTask.operation,
      cook_time: 0,
      main_temp: 0
    }

    if (draggableTask.operation === 'add_product' || draggableTask.operation === 'add_sauce') {
      // Add dragged product to the recipe task
      recipeTask.recipe_task_products = [{ ...draggableTask, amount: 0 }]
      recipeTask.cook_time = 0
    }

    let tasks = [...this.props.tasks[destStepName]]
    tasks.splice(destIndex, 0, recipeTask)

    this.props.updateTasks({ [destStepName]: tasks })
  }

  removeTask = (stepName, index) => {
    let stepTasks = [...this.props.tasks[stepName]]
    stepTasks.splice(index, 1)
    this.props.updateTasks({ [stepName]: stepTasks })
  }

  removeProductFromTask = (stepName, taskIndex, productIndex) => {
    let stepTasks = [...this.props.tasks[stepName]]
    let originTask = stepTasks[taskIndex]
    const [recipeTaskProduct] = originTask.recipe_task_products.splice(productIndex, 1)
    this.props.updateTasks({ [stepName]: stepTasks })
  }

  ungroupTask = (stepName, taskIndex, taskProductIndex, ungroupAll = false) => {

    if(ungroupAll) {
      this.ungroupTasks(stepName, taskIndex)
      return
    }
    let stepTasks = [...this.props.tasks[stepName]]
    let originTask = stepTasks[taskIndex]

    // Remove from origin task
    const [recipeTaskProduct] = originTask.recipe_task_products.splice(taskProductIndex, 1)

    // Build new task with removed product
    const newProductTask: RecipeTask = {
      operation: 'add_product',
      recipe_task_products: [recipeTaskProduct],
      cook_time: 0,
      main_temp: 0
    }

    // Insert new task
    stepTasks.splice(taskIndex + 1, 0, newProductTask)
    this.props.updateTasks({ [stepName]: stepTasks })
  }

  ungroupTasks = (stepName, taskIndex) => {
    let stepTasks = [...this.props.tasks[stepName]]
    let originTask = stepTasks[taskIndex]
    let newTaskIndex = taskIndex;

    for (let i = originTask.recipe_task_products.length - 1; i > 0; i--) {
      let productTask = originTask.recipe_task_products[i]

      // remove from origin task
      originTask.recipe_task_products.splice(i, 1)

      const newProductTask: RecipeTask = {
        operation: 'add_product',
        recipe_task_products: [productTask],
        cook_time: 0,
        main_temp: 0
      }

      newTaskIndex++
      // insert new task
      stepTasks.splice(newTaskIndex + 1, 0, newProductTask)
    }

    this.props.updateTasks({ [stepName]: stepTasks })
  }

  searchProduct = (e) => {
    const searchProductQuery = e.target.value

    if (searchProductQuery === '') {
      // Avoid useless fetch when clearing input
      this.clearProductsSearch()
      return
    }

    this.setState({ searchProductQuery }, () =>
      axios.get(`/products/search?q=${searchProductQuery}`).then(({ data }) => {
        this.setState({ searchProductResults: data })
      }).catch(() => this.setFlashMessage('danger', 'Failed to load products'))
    )
  }

  clearProductsSearch = () => {
    this.setState({
      searchProductQuery: '',
      searchProductResults: []
    })
  }

  handleCategoryClick = (categoryId) => {
    const { expandedCategoryId } = this.state

    this.setState({
      expandedCategoryId: categoryId === expandedCategoryId ? null : categoryId,
      expandedSubcategoryId: null
    })
  }

  handleSubcategoryClick = (subcategoryId) => {
    const { expandedSubcategoryId } = this.state

    this.setState({
      expandedSubcategoryId: subcategoryId === expandedSubcategoryId ? null : subcategoryId
    })
  }

  expandProduct = (productId) => {
    if (!productId) {
      // Close expanded product
      this.setState({ expandedProduct: null })
      return
    }

    axios.get(`/products/${productId}/details`).then(({ data }) => {
      this.setState({ expandedProduct: data })
    })
  }

  updateTaskField = (stepName, taskIndex, field, passedValue) => {
    const value = passedValue ? parseInt(passedValue) : 0
    if (isNaN(value)) return

    let stepTasks = [...this.props.tasks[stepName]]
    stepTasks[taskIndex][field] = Math.max(0, value)
    this.props.updateTasks({ [stepName]: stepTasks })
  }

  updateTaskParameter = (stepName, taskIndex, field, passedValue) => {
    const value = passedValue ? parseInt(passedValue) : 0
    if (isNaN(value)) return
    let stepTasks = [...this.props.tasks[stepName]]

    if(field == 'cook_time') {
      stepTasks[taskIndex].cook_time = Math.max(0, value)
    } else if (field == 'main_temp') {
      stepTasks[taskIndex].main_temp = Math.max(0, value)
    }

    this.props.updateTasks({ [stepName]: stepTasks })
  }

  updateTaskProductParameter = (stepName, taskIndex, productIndex, field, passedValue) => {
    const value = passedValue ? parseFloat(passedValue) : 0
    if (isNaN(value)) return
    let stepTasks = [...this.props.tasks[stepName]]
    stepTasks[taskIndex].recipe_task_products[productIndex][field] = Math.max(0, value)
    this.props.updateTasks({ [stepName]: stepTasks })
  }

  updateProductConfigurable = (stepName, taskIndex, productIndex, passedValue) => {
    const currentStepTasks = [...this.props.tasks[stepName]]
    const product = currentStepTasks[taskIndex].recipe_task_products[productIndex].product
    const newValue = passedValue === 1 ? { id: product.id, value: passedValue } : null

    const taskObjectKeys = Object.keys(this.props.tasks)
    taskObjectKeys.forEach(key => {
      const stepTasks = [...this.props.tasks[key]]

      stepTasks.forEach(task => {
        task.recipe_task_products?.forEach(taskProduct => {
          if (taskProduct.product.id === product.id) {
            taskProduct.product.recipe_configurable = newValue
          }
        })
      })

      this.props.updateTasks({ [key]: stepTasks })
    })
  }

  orderedTasks = (stepName) => {
    return sortBy(this.props.tasks[stepName], task => task.task_number)
  }

  render() {
    const {
      currentDraggable, hoveredDraggable, expandedProduct, searchProductQuery,
      searchProductResults, expandedCategoryColIndex, expandedCategoryId, expandedSubcategoryId
    } = this.state

    const isSearchingProducts = searchProductQuery.length > 0

    const showSearchProductResults = !expandedProduct && isSearchingProducts
    const showDefaultView = !expandedProduct && !isSearchingProducts

    return (
      <div className='recipe-editor'>
        <DragDropContext
          onDragStart={this.onDragStart}
          onDragUpdate={this.onDragUpdate}
          onDragEnd={this.onDragEnd}
        >
          <div className='row'>
            <div className='col-sm-6'>
              <div className='recipe-tasks-dropzones-wrapper'>
                {map(STEPS, step =>
                  <Dropzone
                    key={step.name}
                    number={step.number}
                    stepName={step.name}
                    title={step.title}
                    description={step.description}
                    currentDraggable={currentDraggable}
                    hoveredDraggable={hoveredDraggable}
                    orderedTasks={this.orderedTasks(step.name)}
                    ungroupTask={this.ungroupTask}
                    removeTask={this.removeTask}
                    removeProductFromTask={this.removeProductFromTask}
                    cookingModes={this.props.cookingModes}
                    updateTaskProductParameter={this.updateTaskProductParameter}
                    updateTaskField={this.updateTaskField}
                    updateTaskParameter={this.updateTaskParameter}
                    updateProductConfigurable={this.updateProductConfigurable}
                    weightScale={this.props.weightScale}
                  />
                )}
              </div>
            </div>

            <div className='col-sm-6'>
              <SearchProductField
                searchProductQuery={searchProductQuery}
                searchProduct={this.searchProduct}
                clearProductsSearch={this.clearProductsSearch}
                expandedProduct={expandedProduct}
              />

              {expandedProduct &&
                <ProductDescription
                  expandedProduct={expandedProduct}
                  onClose={() => this.expandProduct(null)}
                />
              }

              {showSearchProductResults &&
                <SearchProductResults
                  searchProductResults={searchProductResults}
                  expandProduct={this.expandProduct}
                />
              }

              {showDefaultView &&
                <div className='available-tasks'>
                  <OperationsList />
                  <span className='recipe-tasks-heading'>{t('recipe_products.heading', { scope })}</span>
                  <span className='recipe-tasks-subtext'>{t('recipe_products.subtext', { scope })}</span>
                  <div className='product-categories-list'>
                    <ProductCategories
                      categorizedProducts={this.props.categorizedProducts}
                      expandProduct={this.expandProduct}
                      handleCategoryClick={this.handleCategoryClick}
                      handleSubcategoryClick={this.handleSubcategoryClick}
                      expandedCategoryColIndex={expandedCategoryColIndex}
                      expandedCategoryId={expandedCategoryId}
                      expandedSubcategoryId={expandedSubcategoryId}
                    />
                  </div>
                </div>
              }
            </div>
          </div>
        </DragDropContext>
        <div style={{ position: 'relative' }}>
          {this.renderFlashNotification()}
        </div>
      </div>
    )
  }
}

export default RecipeTasks
