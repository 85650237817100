import React, { useState } from 'react'
import Modal from '../shared/modal'
import PlusMinusWrapper from '../shared/plusMinusWrapper'
import Icon from '../shared/icon'
import { ReplenishmentOrderAction } from './types'
import { t } from 'src/i18n'

type BowlMode = 'view' | 'edit' | 'complete'

const scope = 'replenishment.bowls'

const ReplenishmentCompletedModalContent = ({ closeModal, amount, slotNumber, toggleSidebar }) => {

  const openBasket = () => {
    toggleSidebar(true)
    closeModal()
  }

  return (
    <div className='bowl-replenishment-complete'>
      <div className='title-icon'>
        <Icon name='replace'/>
      </div>
      <div className='heavy-text'>{t('bowls_header', { scope, slotNumber: slotNumber })}</div>
      <div className='plates-icon-container'>
        <Icon name='plate' color='white'/>
      </div>
      <p>
      {t('bowls_message', { scope, amount: amount })}
      </p>
      <div className='buttons'>
        <button className='btn btn-secondary' onClick={openBasket}>{t('replenishment.replenishment_modal.go_to_basket')}</button>
        <button className='btn btn-primary' onClick={closeModal}>{t('replenishment.replenishment_modal.continue')}</button>
      </div>
    </div>
  )
}

export const BowlReplenishmentModal = ({ containerSlot, container, closeModal, replenishmentOrder, updateCart, toggleSidebar }) => {
  const [mode, setMode] = useState<BowlMode>('view')
  const [replenishmentAmount, setReplenishmentAmount] = useState(container.initial_amount)

  const amountLeft = container.initial_amount - container.used_amount

  const addToReplenishmentOrder = () => {
    const plateReplenishment = {
      slotNumber: containerSlot,
      amount: replenishmentAmount
    }
    updatePlateReplenishments(plateReplenishment)
  }

  const containerAlreadyReplenished = (containerSlotNumber) => {
    return replenishmentOrder.plateReplenishments.some(replenishment => (replenishment.slotNumber == containerSlotNumber))
  }

  const replenishedContainer = () => {
    return replenishmentOrder.plateReplenishments.find(replenishment => (replenishment.slotNumber == containerSlot))
  }

  const updatePlateReplenishments = (newPlateReplenishment) => {
    if(containerAlreadyReplenished(newPlateReplenishment.slotNumber)) {

      const updatedPlateReplenishments = replenishmentOrder.plateReplenishments
        .map(replenishment => (replenishment.slotNumber == newPlateReplenishment.slotNumber)
        ? {...newPlateReplenishment, id: replenishment.id} : replenishment)

      updateCart({...replenishmentOrder, plateReplenishments: updatedPlateReplenishments}, ReplenishmentOrderAction.ADD_ITEM)
    } else {
      updateCart({...replenishmentOrder, plateReplenishments: [...replenishmentOrder.plateReplenishments, newPlateReplenishment]}, ReplenishmentOrderAction.ADD_ITEM)
    }
  }

  const headerContent = () => {
    if(containerAlreadyReplenished(containerSlot))  {
      return (
        <>
          <h3 className='header'>{t('bowl_stand', {scope, slotNumber: containerSlot})}</h3>
          <span className='replenishment-header-text'>to refill</span>
        </>
      )
    }

    return (<h3 className='header'>{t('bowl_stand', {scope, slotNumber: containerSlot})}</h3>)
  }

  const amount = () => {
    if(containerAlreadyReplenished(containerSlot)) {
      return (
        <>
          <span>{amountLeft} + </span>
          <span style={{color: '#CA56F3'}}>{replenishedContainer().amount}</span>
          <span> / {container.initial_amount}</span>
        </>
      )
    } else {
      return (
        <span>{amountLeft} / {container.initial_amount}</span>
      )
    }
  }

  return (
    <>
      <Modal onClose={closeModal}
      headerContent={ mode === 'complete' ? null : headerContent()}>
      <div className='container-modal show'>
      {mode != 'complete' &&
        <>
          <div className='product-info'>
            <div className="plates-icon-container">
              <Icon name='plate' color='white'/>
            </div>
            <div className="product-content">
              {amount()}
            </div>
          </div>
          {mode === 'edit' &&
            <div className='attribute-wrapper'>
              <span className='input-label' style={{ marginRight: '10px' }}>Amount</span>
              <PlusMinusWrapper
                step={10}
                value={replenishmentAmount}
                onChange={(_, value) => setReplenishmentAmount(value)}
              >
                <div className='subwrapper'>
                  <input
                    type='text'
                    value={replenishmentAmount}
                    maxLength={3}
                    onChange={({ target: { value } }) => setReplenishmentAmount(value)}
                  />
                </div>
              </PlusMinusWrapper>
            </div>
          }
          <div className='buttons'>
            <button className='btn btn-secondary' onClick={closeModal}>{t('cancel')}</button>
            {mode === 'view' && <button className='btn btn-primary btn-refill' onClick={() => setMode('edit')}>{t('refill_stand', { scope })}</button>}
            {mode === 'edit' && <button className='btn btn-primary' onClick={() => {addToReplenishmentOrder(); setMode('complete')}}>{t('save')}</button>}
          </div>
        </>
      }
      {mode === 'complete' &&
        <ReplenishmentCompletedModalContent
          closeModal={closeModal}
          toggleSidebar={toggleSidebar}
          amount={replenishmentAmount}
          slotNumber={containerSlot}
        />
      }
      </div>
    </Modal>
    </>
  )
}
